import Vue from 'vue'
import Router from 'vue-router'
import authApi from '@api/auth' //eslint-disable-line
import routes from '@routes'
import { store } from '@store' //eslint-disable-line
import { initRouter } from '@technologyadvice/relay-frontend/src/util/router'

Vue.use(Router)

const portal = 'accounts'

const router = new Router({
    mode: 'history',
    base: `/${portal}/`,
    routes,
    scrollBehavior() {
        return { x: 0, y: 0 }
    },
})

export default initRouter({
    Vue, authApi, store, router, portal,
})
