<template>
    <BaseView
        :is-loading="false"
        :load-error="!isUser">
        <template #content>
            <CardChangePassword
                :card-title="`Reset Password`"
                :reset-password-user-uuid="userUuid">
            </CardChangePassword>
        </template>
    </BaseView>
</template>

<script>
import { BaseView } from '@technologyadvice/relay-frontend'
import CardChangePassword from '@components/users/edit/CardChangePassword.vue'
import { mapGetters } from 'vuex'

export default {
    name: 'ResetUserPassword',
    components: {
        CardChangePassword,
        BaseView,
    },
    props: {
        userUuid: {
            type: String,
            required: true,
        },
    },
    computed: {
        ...mapGetters('auth', [
            'user',
        ]),
        isUser() {
            return this.userUuid === this.user.uuid
        },
    },
}
</script>
