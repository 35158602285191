<template>
    <BaseCard>
        <template #title>
            <div>
                <i
                    aria-hidden="true"
                    class="fa fa-camera fa-md card__title-icon" />
                Avatar
            </div>
        </template>

        <template #body>
            <div class="text-center">
                <avatar
                    :image-src="avatarImage"
                    size="9rem"
                    centered>
                </avatar>

                <div
                    v-if="canManageUser"
                    class="mt-4 avatar__actions">
                    <div class="h6 mb-3">
                        Admin Account Actions
                    </div>

                    <button
                        v-if="value.status === 'active'"
                        class="btn btn-secondary-transparent btn-block mx-auto"
                        @click="handleUserAction('pause')">
                        <i
                            aria-hidden="true"
                            class="fa fa-pause-circle mr-1 mb-2" />Pause
                    </button>

                    <button
                        v-if="value.status === 'inactive'"
                        class="btn btn-success-transparent btn-block mx-auto"
                        @click="handleUserAction('activate')">
                        <i
                            aria-hidden="true"
                            class="fa fa-play-circle mr-1 mb-2" />Activate
                    </button>

                    <button
                        class="btn btn-danger-transparent btn-block mx-auto"
                        @click="handleUserAction('delete')">
                        <i
                            aria-hidden="true"
                            class="fa fa-trash mr-1" />Delete
                    </button>
                </div>
            </div>
        </template>
    </BaseCard>
</template>

<script>
import { Avatar, BaseCard } from '@technologyadvice/relay-frontend'
import md5 from 'md5'
import Swal from 'sweetalert2'
import { mapActions } from 'vuex'
import api from '@api/accounts'

export default {
    components: {
        Avatar,
        BaseCard,
    },
    props: {
        value: {
            type: Object,
            default: null,
            required: true,
        },
        canManageUser: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        avatarImage() {
            return this.value.avatar_url
                ? this.value.avatar_url
                : `https://www.gravatar.com/avatar/${md5(this.value.email)}?s=288&d=mp`
        },
    },
    methods: {
        ...mapActions('users', [
            'deleteUserByUuid',
            'updateUserByUuid',
        ]),

        deleteUser() {
            const { uuid } = this.value

            api.deleteUser(uuid)
                .then(() => {
                    this.deleteUserByUuid(uuid)
                    this.$_toasterSuccess('User deleted!')
                    this.$router.push({ path: '/users' })
                })
                .catch(() => {
                    this.$_toasterDanger('Could not delete user. Please try again or contact support.')
                })
        },

        handleUserAction(action = 'activate') {
            Swal.fire({
                title: `User ${action.charAt(0).toUpperCase() + action.slice(1)}`,
                text: `Confirm that you want to ${action}: ${this.value.email}?`,
                type: action === 'activate' ? 'success' : 'warning',
                showCancelButton: true,
                confirmButtonText: `Yes, ${action}`,
                reverseButtons: true,
            })
                .then(result => {
                    if (result.value) {
                        if (action !== 'delete') {
                            const status = action === 'activate' ? 'active' : 'inactive'
                            const successMsg = action === 'activate' ? 'User activated!' : 'User paused!'

                            this.updateUser(status, successMsg)
                        } else {
                            this.deleteUser()
                        }
                    }
                })
        },

        updateUser(status, successMsg = 'User activated!') {
            const { uuid } = this.value

            api.updateUser({
                uuid,
                status,
            })
                .then(() => {
                    this.$_toasterSuccess(successMsg)
                    this.updateUserByUuid({
                        uuid,
                        status,
                    })
                })
                .catch(() => {
                    this.$_toasterDanger('Could not update user. Please try again or contact support.')
                })
        },
    },
}
</script>
