<template>
    <BaseCard
        :is-loading="isLoading"
        :is-error="isError">
        <template #header-link>
            <router-link
                to="/integrations"
                class="mr-auto">
                <i
                    aria-hidden="true"
                    class="fas fa-chevron-left mr-2" />Back to Integrations
            </router-link>
        </template>
        <template #body>
            <div class="d-flex flex-wrap align-items-center">
                <img
                    src="../../../../assets/imgs/logo/zoominfo-logo-light.svg"
                    alt="ZoomInfo Logo"
                    class="zoominfo-logo mr-4">
                <div class="col-xl-6 px-0 mt-xl-0 mt-3">
                    <h4>ZoomInfo</h4>
                    <p>
                        ZoomInfo allows you to get contact data for people at the
                        account showing buyer intent for your products in Traction
                    </p>

                    <hr>

                    <ButtonUpgrade v-if="upgradeRequired"></ButtonUpgrade>
                    <IntegrationStatusIndicator
                        v-else
                        :connected="integrationEnabled">
                    </IntegrationStatusIndicator>
                    <p
                        class="pt-2"
                        :class="accountAdmin ? 'text-lighter' : 'text-warning'">
                        {{
                            accountAdmin
                                ? `Managed by ${accountAdmin?.display_name} (${accountAdmin?.email})`
                                : ('Admin Not Assigned: '
                                    + 'Please contact your Client Success Manager '
                                    + 'to have an admin assigned to your account')
                        }}
                    </p>
                </div>
            </div>

            <NavHeaderTabs
                :routes="routes"
                class="mt-4">
            </NavHeaderTabs>
            <router-view></router-view>
        </template>
    </BaseCard>
</template>

<script>
import { BaseCard, NavHeaderTabs, ButtonUpgrade } from '@technologyadvice/relay-frontend'
import IntegrationStatusIndicator from '@components/integrations/IntegrationStatusIndicator.vue'
import { mapGetters } from 'vuex'
import { mapState, mapActions } from 'pinia'
import { useIntegrationsStore } from '@store/integrations'
import accountApi from '@api/accounts'

export default {
    components: {
        BaseCard,
        NavHeaderTabs,
        IntegrationStatusIndicator,
        ButtonUpgrade,
    },
    data() {
        return { accountAdmin: null }
    },
    computed: {
        ...mapGetters('auth', [
            'user',
        ]),
        ...mapState(useIntegrationsStore, [
            'integrations',
            'loading',
            'error',
        ]),
        isLoading() {
            return this.loading
        },
        isError() {
            return this.error
        },
        integrationEnabled() {
            return this.integrations.find(integration => integration.type === 'zoom_info')?.enabled || false
        },
        upgradeRequired() {
            return !this.user?.has_active_plan
                || (!this.user?.gates?.integrations || this.user?.gates?.integrations === 'false')
        },
        routes() {
            let options = [
                {
                    name: 'About',
                    label: 'About ZoomInfo',
                },
            ]

            if (!this.upgradeRequired) {
                options = [
                    ...options,
                    {
                        name: 'Installation',
                        label: 'Installation Instructions',
                    },
                    {
                        name: 'Settings',
                        label: 'Settings',
                    },
                ]
            }

            return options
        },
    },
    created() {
        if (!this.user.features.includes('integration-hub')) {
            this.$router.push({ name: '404' })
        } else {
            this.load()
            this.fetchAccountAdmin()
        }
    },
    methods: {
        ...mapActions(useIntegrationsStore, [ 'load' ]),
        fetchAccountAdmin() {
            accountApi.getAccountAdmin()
                .then(({ data }) => {
                    this.accountAdmin = data.user
                })
                .catch(error => {
                    console.error('An error occurred while trying to get the Admin of this account', error)
                })
        },
    },
}
</script>
