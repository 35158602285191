<template>
    <BaseView
        :title="title"
        sub-title="Modify plan and its features">
        <template #header-link>
            <router-link
                tag="button"
                :to="{name: 'Plans'}"
                class="btn btn-link">
                <i
                    class="fas fa-arrow-left mr-2"
                    :aria-hidden="true" />
                Return to Plans
            </router-link>
        </template>

        <template #content>
            <BaseCard>
                <template #title>
                    <i
                        class="fas fa-list-ul text-primary mr-2"
                        :aria-hidden="true" />
                    Basic Information
                </template>

                <template #body>
                    <ValidationObserver
                        v-slot="{handleSubmit, invalid}"
                        class="row">
                        <ValidationProvider
                            v-slot="{ errors }"
                            rules="required"
                            name="plan name"
                            class="col-12 col-xl-6 pb-3">
                            <label class="card__info-label required">
                                Name
                            </label>
                            <div>
                                <input
                                    v-model="plan.name"
                                    class="form-control"
                                    :class="{ 'is-invalid' : errors.length }"
                                    placeholder="Name the plan...">
                            </div>
                            <MessageValidationError
                                v-if="errors.length"
                                :msg="errors[0]">
                            </MessageValidationError>
                        </ValidationProvider>

                        <div class="col-12 col-xl-6 pb-3">
                            <label class="card__info-label">
                                Code
                            </label>
                            <div>
                                {{ plan.code }}
                            </div>
                        </div>

                        <div class="col-12 pb-3">
                            <label class="card__info-label">
                                Description
                            </label>

                            <textarea
                                v-model="plan.description"
                                rows="6"
                                class="form-control"
                                placeholder="Describe the plan..." />
                        </div>
                        <div class="col-12">
                            <label class="card__info-label">
                                Plan Status
                            </label>
                            <ToggleButton
                                v-model="plan.is_active"
                                :label="plan.is_active ? 'Active' : 'Inactive'">
                            </ToggleButton>
                        </div>

                        <div class="col-12 d-flex justify-content-end">
                            <IconButton
                                class="btn-primary"
                                icon="fa-save"
                                label="Save"
                                :loading="isSubmitting"
                                :disabled="invalid"
                                @click="handleSubmit(saveBasicInfo)"></IconButton>
                        </div>
                    </ValidationObserver>
                </template>
            </BaseCard>

            <BaseCard>
                <template #title>
                    <i
                        class="fas fa-cube text-primary mr-2"
                        :aria-hidden="true" />
                    Features
                </template>

                <template #header-link>
                    <button
                        class="btn btn-link"
                        @click.prevent="() => toggleModalPlanAttribute()">
                        <i
                            class="fas fa-plus mr-2"
                            :aria-hidden="true" />
                        Add Feature
                    </button>
                </template>

                <template #body>
                    <VueGoodTable
                        :cols="columns"
                        :rows="plan.attributes"
                        class-name="edit-plan-table">
                        <template #table-row="props">
                            <span
                                v-if="props.column.field === 'description'"
                                class="text-wrap">
                                {{ props.row.description || '--' }}
                            </span>

                            <span v-else-if="props.column.field === 'actions'">
                                <div class="d-flex justify-content-end">
                                    <button
                                        class="btn btn-link mr-3"
                                        @click.prevent="() => toggleModalPlanAttribute(props.row)">
                                        <i
                                            class="fas fa-edit mr-1"
                                            :aria-hidden="true" />
                                        Edit
                                    </button>

                                    <button
                                        class="btn btn-link text-danger"
                                        @click.prevent="() => deleteAlert(props.row.uuid)">
                                        <i
                                            class="fas fa-trash mr-1"
                                            :aria-hidden="true" />
                                        Delete
                                    </button>

                                </div>
                            </span>
                        </template>
                    </VueGoodTable>

                    <ModalPlanAttribute
                        v-if="showModalPlanAttribute"
                        :plan-uuid="uuid"
                        :plan-attribute="selectedPlanAttribute"
                        @close="toggleModalPlanAttribute"
                        @reload="getPlan">
                    </ModalPlanAttribute>
                </template>
            </BaseCard>
        </template>
    </BaseView>
</template>

<script>
import {
    BaseView, BaseCard, ToggleButton, IconButton, MessageValidationError,
} from '@technologyadvice/relay-frontend'
import ModalPlanAttribute from '@components/plans/ModalPlanAttribute.vue'
import VueGoodTable from '@technologyadvice/ta-good-table'
import api from '@api/accounts'
import Swal from 'sweetalert2'

export default {
    components: {
        BaseView,
        BaseCard,
        ModalPlanAttribute,
        VueGoodTable,
        ToggleButton,
        IconButton,
        MessageValidationError,
    },
    props: {
        uuid: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            plan: {
                is_active: false,
                attributes: [],
            },
            isLoading: true,
            isSubmitting: false,
            isError: false,
            selectedPlanAttribute: null,
            showModalPlanAttribute: false,
        }
    },
    computed: {
        title() {
            return `Plan Management: ${this.plan.name}`
        },
        columns() {
            return [
                {
                    label: 'Name',
                    field: 'name',
                },
                {
                    label: 'Description',
                    field: 'description',
                },
                {
                    label: 'Default Value',
                    field: 'value',
                },
                {
                    label: '',
                    field: 'actions',
                    sortable: false,
                },
            ]
        },
    },
    created() {
        this.getPlan()
    },
    methods: {
        getPlan() {
            return api.getPlan(this.uuid)
                .then(({ data }) => {
                    this.plan = data.data
                })
                .catch(() => {
                    this.isError = true
                })
                .finally(() => {
                    this.isLoading = false
                })
        },
        saveBasicInfo() {
            this.isSubmitting = true
            return api.updatePlan(this.uuid, this.plan)
                .then(() => {
                    this.$_toasterSuccess('Successfully updated the plan')
                })
                .catch(() => {
                    this.$_toasterDanger('Failed to update the plan')
                })
                .finally(() => {
                    this.isSubmitting = false
                })
        },
        deleteAlert(uuid) {
            Swal.fire({
                title: 'Are you sure you want to delete this feature?',
                text: `This will apply to all existing plans. 
                    This action is permanent and cannot be reversed.`,
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Delete Feature',
                reverseButtons: true,
            })
                .then(result => {
                    if (result.value) {
                        this.deletePlanAttribute(uuid)
                    }
                })
        },
        deletePlanAttribute(uuid) {
            return api.deletePlanAttribute(uuid)
                .then(() => {
                    this.$_toasterSuccess('Successfully deleted plan feature')
                    this.getPlan()
                })
                .catch(() => {
                    this.$_toasterDanger('Failed to delete plan feature')
                })
        },
        toggleModalPlanAttribute(planAttribute = null) {
            this.selectedPlanAttribute = planAttribute

            this.showModalPlanAttribute = !this.showModalPlanAttribute
        },
    },
}
</script>
