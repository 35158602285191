import brandApi from "@api/brands" //eslint-disable-line
import Vue from 'vue'

export default {
    namespaced: true,
    state: {
        loading: {
            brand: false,
            brandList: false,
            brandSave: false,
        },
        errors: {
            brand: null,
            brandList: null,
            brandUserSave: null,
            brandSave: null,
        },
        brandList: [],
        brand: {},
    },
    getters: {
        brand: ({ brand }) => brand,
        brandList: state => state.brandList,
        brandByUuid: state => uuid => state.brands.find(brand => brand.uuid === uuid),
        getLoadingState: state => name => state.loading[name],
        getErrorState: state => name => state.errors[name],
    },
    mutations: {
        setError(state, error) {
            Vue.set(state.errors, error.name, error.value)
        },
        setLoadingState(state, loading) {
            Vue.set(state.loading, loading.name, loading.value)
        },
        setBrand(state, data) {
            Vue.set(state, 'brand', data.data)
        },
        setBrandList(state, brands) {
            Vue.set(state, 'brandList', brands.data)
        },
        updateBrandProperty(state, payload) {
            Vue.set(state.brand, payload.property, payload.value)
        },
        addBrand(state, brand) {
            state.brandList.push(brand)
        },
    },
    actions: {
        loadBrand({ commit }, uuid) {
            commit('setLoadingState', { name: 'brand', value: true })

            return brandApi
                .getBrand(uuid)
                .then(response => {
                    const { data } = response.data
                    commit('setBrand', data)
                })
                .catch(() => {
                    commit('setError', { name: 'brand', value: true })
                })
                .finally(() => {
                    commit('setLoadingState', { name: 'brand', value: false })
                })
        },
        setBrandList({ commit }) {
            commit('setLoadingState', { name: 'brandList', value: true })

            brandApi
                .getBrands()
                .then(response => {
                    const { data } = response.data

                    commit('setBrandList', data)
                })
                .catch(() => {
                    commit('setError', {
                        name: 'brandList',
                        value: true,
                    })
                })
                .finally(() => {
                    commit('setLoadingState', { name: 'brandList', value: false })
                })
        },
        saveBrand({ commit, state }) {
            commit('setLoadingState', { name: 'brandSave', value: true })
            commit('setError', { name: 'brandSave', value: false })

            return brandApi
                .updateBrand(state.brand.uuid, state.brand)
                .catch(res => {
                    commit('setError', {
                        name: 'brandSave',
                        value: res.response,
                    })
                })
                .finally(() => {
                    commit('setLoadingState', { name: 'brandSave', value: false })
                })
        },
        addBrand({ commit }, brandPayload) {
            commit('setLoadingState', { name: 'brandSave', value: true })
            commit('setError', { name: 'brandSave', value: false })

            return brandApi
                .addBrand(brandPayload)
                .then(response => {
                    const brand = {
                        ...response.data.data,
                    }

                    commit('addBrand', brand)
                })
                .catch(res => {
                    commit('setError', {
                        name: 'brandSave',
                        value: res.response,
                    })
                })
                .finally(() => {
                    commit('setLoadingState', { name: 'brandSave', value: false })
                })
        },
        clearError({ commit }, name) {
            commit('setError', {
                name,
                value: null,
            })
        },
        initializeBrandManager({ dispatch }) {
            dispatch('setBrandList')
        },
        updateBrandProperty({ commit }, payload) {
            commit('updateBrandProperty', payload)
        },
    },
}
