<template>
    <BaseView
        :title="title"
        sub-title="Modify feature and its behavior">
        <template #header-link>
            <router-link
                tag="button"
                :to="{name: 'Attributes'}"
                class="btn btn-link">
                <i
                    class="fas fa-arrow-left mr-2"
                    :aria-hidden="true" />
                Return to Features
            </router-link>
        </template>

        <template #content>
            <BaseCard>
                <template #title>
                    <i
                        class="fas fa-list-ul text-primary mr-2"
                        :aria-hidden="true" />
                    Basic Information
                </template>

                <template #body>
                    <ValidationObserver
                        v-slot="{handleSubmit, invalid}"
                        class="row">
                        <ValidationProvider
                            v-slot="{ errors }"
                            rules="required"
                            name="name"
                            class="col-12 col-xl-6 mb-3">
                            <label class="card__info-label required">
                                Name
                            </label>
                            <div>
                                <input
                                    v-model="attribute.name"
                                    :class="{ 'is-invalid' : errors.length }"
                                    class="form-control"
                                    placeholder="Name the plan...">
                            </div>
                            <MessageValidationError
                                v-if="errors.length"
                                :msg="errors[0]">
                            </MessageValidationError>
                        </ValidationProvider>

                        <div class="col-12 col-xl-6 mb-3">
                            <label class="card__info-label">
                                Code
                            </label>
                            <div>
                                {{ attribute.code }}
                            </div>
                        </div>

                        <ValidationProvider
                            v-slot="{errors}"
                            rules="required"
                            tag="div"
                            class="col-12 mb-3"
                            name="type">
                            <label class="card__info-label required">
                                Type
                            </label>
                            <select
                                v-model="attribute.type"
                                class="form-control"
                                :class="{'is-invalid': errors && errors.length}">
                                <option
                                    :value="null"
                                    disabled
                                    selected>
                                    Select a type...
                                </option>

                                <option
                                    v-for="(type, i) in types"
                                    :key="`type-${i}`"
                                    :value="type.name">
                                    {{ type.label }}
                                </option>
                            </select>

                            <MessageValidationError
                                v-if="errors && errors.length"
                                :msg="errors[0]">
                            </MessageValidationError>
                        </ValidationProvider>

                        <div
                            class="col-12 py-3">
                            <label class="card__info-label">
                                Description
                            </label>

                            <textarea
                                v-model="attribute.description"
                                rows="6"
                                class="form-control"
                                placeholder="Describe the plan..." />
                        </div>

                        <div
                            v-if="attribute.type && attribute.type !== 'solution_categories'"
                            tag="div"
                            class="col-12 mb-3">
                            <label class="card__info-label">
                                Default Value
                            </label>

                            <div v-if="attribute.type === 'boolean'">
                                <OptionBoxes
                                    inline
                                    type="radio"
                                    :options="trueFalseOptions"
                                    @change="selectTrueFalseOptions">
                                </OptionBoxes>
                            </div>

                            <div v-else>
                                <input
                                    v-model="attribute.value"
                                    class="form-control">
                            </div>
                        </div>

                        <div class="col-12">
                            <div class="d-flex justify-content-end">
                                <IconButton
                                    class="btn-primary"
                                    icon="fa-save"
                                    label="Save"
                                    :loading="isSubmitting"
                                    :disabled="invalid"
                                    @click="handleSubmit(update)"></IconButton>
                            </div>
                        </div>
                    </ValidationObserver>
                </template>
            </BaseCard>
        </template>
    </BaseView>
</template>

<script>
import api from '@api/accounts'
import {
    BaseView, BaseCard, OptionBoxes, IconButton, MessageValidationError,
} from '@technologyadvice/relay-frontend'

export default {
    components: {
        BaseView,
        BaseCard,
        OptionBoxes,
        IconButton,
        MessageValidationError,
    },
    props: {
        uuid: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            attribute: {},
            isLoading: true,
            isError: false,
            isSubmitting: false,
            trueFalseOptions: [
                {
                    label: 'True',
                    property: 'true',
                    enabled: false,
                },
                {
                    label: 'False',
                    property: 'false',
                    enabled: false,
                },
            ],
        }
    },
    computed: {
        title() {
            return `Feature Management: ${this.attribute.name}`
        },
        types() {
            return this.$readableTypes.all()
        },
    },
    created() {
        this.getAttribute()
    },
    methods: {
        selectTrueFalseOptions(value) {
            this.trueFalseOptions = this.trueFalseOptions.map(option => ({
                label: option.label,
                property: option.property,
                enabled: value[option.property],
            }))

            const { property } = this.trueFalseOptions.find(({ enabled }) => enabled)

            this.attribute.value = property
        },
        getAttribute() {
            api.getAttribute(this.uuid)
                .then(({ data }) => {
                    this.attribute = data.data
                })
                .catch(() => {
                    this.isError = true
                })
                .finally(() => {
                    this.isLoading = false

                    this.trueFalseOptions = this.trueFalseOptions.map(option => ({
                        label: option.label,
                        property: option.property,
                        enabled: this.attribute.value === option.property,
                    }))
                })
        },
        update() {
            if (this.attribute.type === 'solution_categories') this.attribute.value = null
            this.isSubmitting = true
            api.updateAttribute(this.uuid, this.attribute)
                .then(() => {
                    this.$_toasterSuccess('Successfully updated feature')
                })
                .catch(() => {
                    this.$_toasterDanger('Failed to update feature')
                })
                .finally(() => {
                    this.isSubmitting = false
                })
        },
    },
}
</script>
