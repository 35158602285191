<template>
    <div
        class="promotion card p-4">
        <div class="promotion-header d-flex pb-4 justify-content-between">
            <div>
                <h5
                    class="text-uppercase text-success">
                    Exclusive Offer
                </h5>
                <h3 class="font-weight-light mb-0">
                    Unlock 3 Months of First-Party Traction Data with ZoomInfo Sales – Free!
                </h3>
            </div>
            <img
                class="align-self-start cursor-pointer"
                alt="Close Button"
                :src="CloseButtonSvg"
                @click="closeCard">
        </div>

        <div class="promotion-body d-flex align-items-center pb-4">
            <div class="promotion-text d-flex flex-column align-self-center text-primary col-4 pr-5">
                <p>
                    We’re thrilled to introduce a new feature that brings our exclusive first-party intent data
                    directly into the ZoomInfo Sales platform!
                </p>
                <p>
                    Now you can easily access unique intent data from
                    our own engaged audience, helping you connect with in-market buyers with minimal overlap from
                    other third-party intent providers.
                </p>

                <p>
                    With the ZoomInfo integration, you can:
                </p>
                <ul class="text-secondary pl-4">
                    <li>Identify champions within the buying committee</li>
                    <li>Time your outreach precisely when prospects are most engaged</li>
                    <li>Reduce churn by spotting existing customers who are researching competitors</li>
                </ul>

                <p>
                    To celebrate this launch, we’re offering 3 months of <strong>Traction Insights</strong>
                    for up to 3 categories FREE for existing ZoomInfo Sales customers who sign up by the end of 2024.
                </p>
            </div>

            <div class="promotion-image col-8">
                <img
                    :src="ZiTractionPromo"
                    alt="ZoomInfo and Traction Promotion"
                    class="img-fluid">
            </div>
        </div>

        <div class="promotion-footer d-flex justify-content-end">
            <a
                v-if="isHomePage"
                href="/client/dashboard"
                class="btn btn-link align-content-center justify-content-center font-weight-bold pr-4 m-2">
                Go to Dashboard
            </a>
            <button
                v-else
                class="btn btn-link align-content-center justify-content-center font-weight-bold pr-4 m-2"
                @click="closeCard">
                Dismiss
            </button>

            <a
                :href="`https://solutions.technologyadvice.com/traction-insights/?utm_campaign=` +
                    `Traction%20Insights&utm_source=traction&utm_medium=traction%20platform%20promotion`"
                target="_blank"
                class="btn btn-primary d-flex align-items-center justify-content-center font-weight-bold">
                Claim Free Access
            </a>
        </div>
    </div>
</template>

<script>
import ZiTractionPromo from '@/assets/imgs/promo/zi-traction-integration-collab-logo.png'
import CloseButtonSvg from '@/assets/imgs/logo/close-button.svg'

export default {
    name: 'PromotionCard',
    props: {
        isHomePage: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            ZiTractionPromo,
            CloseButtonSvg,
        }
    },
    methods: {
        closeCard() {
            this.$emit('close')
        },
    },
}
</script>
