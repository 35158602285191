var render = function render(){var _vm=this,_c=_vm._self._c;return _c('BaseCard',{staticClass:"plans",attrs:{"is-loading":_vm.isLoading},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('i',{staticClass:"fas fa-cubes text-primary mr-2",attrs:{"aria-hidden":true}}),_vm._v(" Plans ")]},proxy:true},{key:"header-link",fn:function(){return [_c('button',{staticClass:"btn btn-link",on:{"click":() => _vm.showModalAddPlan = true}},[_c('i',{staticClass:"fas fa-plus mr-1",attrs:{"aria-hidden":true}}),_vm._v(" New Plan ")])]},proxy:true},{key:"body",fn:function(){return [_c('VueGoodTable',{attrs:{"cols":_vm.columns,"rows":_vm.plans,"class-name":"plans__table","sort-options":{
                enabled: true,
                initialSortBy: {field: 'display_order', type: 'asc'}
            }},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'clients_on_plan')?_c('span',[_c('a',{attrs:{"href":`/client/clients/list?plan=${props.row.name}`,"tag":"button"}},[_vm._v(" "+_vm._s(props.row.clients_on_plan)+" ")])]):(props.column.field === 'is_active')?_c('span',[_c('ToggleButton',{attrs:{"id":'planIndexToggle-' + props.row.uuid},on:{"input":e => _vm.updatePlanStatus(props.row)},model:{value:(props.row.is_active),callback:function ($$v) {_vm.$set(props.row, "is_active", $$v)},expression:"props.row.is_active"}})],1):(props.column.field === 'actions')?_c('span',[_c('router-link',{staticClass:"btn btn-link",attrs:{"to":{
                            name: 'PlanManagementEditPlan',
                            params: {
                                uuid: props.row.uuid
                            }
                        },"tag":"button","disabled":_vm.isSwappingPlanOrders}},[_c('i',{staticClass:"fas fa-edit mr-1",attrs:{"aria-hidden":true}}),_vm._v(" Edit ")]),_c('button',{directives:[{name:"tooltip",rawName:"v-tooltip",value:('Move plan up'),expression:"'Move plan up'"}],staticClass:"btn btn-primary-transparent ml-4 mr-2 plans__button",attrs:{"disabled":props.row.display_order == 1 || _vm.isSwappingPlanOrders},on:{"click":function($event){_vm.updatePlanDisplayOrder(props.row.uuid, (props.row.display_order - 1))}}},[(!_vm.isSwappingPlanOrders)?_c('i',{staticClass:"fas fa-angle-up",attrs:{"aria-hidden":"true"}}):_c('i',{staticClass:"fas fa-spinner fa-spin",attrs:{"aria-hidden":"true"}})]),_c('button',{directives:[{name:"tooltip",rawName:"v-tooltip",value:('Move plan down'),expression:"'Move plan down'"}],staticClass:"btn btn-primary-transparent mr-2 plans__button",attrs:{"disabled":props.row.display_order == _vm.plans.length || _vm.isSwappingPlanOrders},on:{"click":function($event){_vm.updatePlanDisplayOrder(props.row.uuid, (props.row.display_order + 1))}}},[(!_vm.isSwappingPlanOrders)?_c('i',{staticClass:"fas fa-angle-down",attrs:{"aria-hidden":"true"}}):_c('i',{staticClass:"fas fa-spinner fa-spin",attrs:{"aria-hidden":"true"}})])],1):_vm._e()]}}])}),(_vm.showModalAddPlan)?_c('ModalAddPlan',{on:{"reload":_vm.getPlans,"close":() => _vm.showModalAddPlan = false}}):_vm._e()]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }