import axios from '@axios' //eslint-disable-line

export default {
    activateUser(uuid) {
        return axios.put(`/users/${uuid}`, { status: 'active' })
    },
    createUser(data) {
        const postData = {
            ...data,
        }
        return axios.post('/users', postData)
    },
    deleteUser(uuid) {
        return axios.delete(`/users/${uuid}`)
    },
    getUsers() {
        return axios.get('/users')
    },
    getUser(userUuid) {
        return axios.get(`/users/${userUuid}`)
    },
    updateUser(data) {
        return axios.put(`/users/${data.uuid}`, data)
    },
    getAccountAdmin() {
        return axios.get('/users/account-admin')
    },
    sendPasswordResetEmail(email) {
        return axios.post('/password/email', { email })
    },
    validatePasswordToken(data) { // should contain email and token
        return axios.post('/password/reset/validate', data)
    },
    resetUserPassword(data) { // should contain email, token, password, and password_confirmation
        return axios.post('/password/reset', data)
    },
    sendInvite(data) {
        return axios.post('/invites', data)
    },
    checkInvite(params) {
        return axios.get('/invites', { params })
    },
    acceptInvite(data) {
        return axios.post('/invites/accept', data)
    },
    joinAccount(uuid, data) {
        return axios.put(`/users/${uuid}/join-account`, data)
    },
    getPlans() {
        return axios.get('/plans')
    },
    getPlan(uuid) {
        return axios.get(`/plans/${uuid}`)
    },
    updatePlan(uuid, body) {
        return axios.put(`/plans/${uuid}`, body)
    },
    storePlan(body) {
        return axios.post('/plans', body)
    },
    getAttributes() {
        return axios.get('/attributes')
    },
    getAttribute(uuid) {
        return axios.get(`/attributes/${uuid}`)
    },
    storeAttribute(body = {}) {
        return axios.post('/attributes', body)
    },
    updateAttribute(uuid, body = {}) {
        return axios.put(`/attributes/${uuid}`, body)
    },
    storePlanAttribute(body) {
        return axios.post('/plan-attributes', body)
    },
    updatePlanAttribute(uuid, body = {}) {
        return axios.put(`/plan-attributes/${uuid}`, body)
    },
    deletePlanAttribute(uuid) {
        return axios.delete(`/plan-attributes/${uuid}`)
    },
    getFeatureToggles() {
        return axios.get('/features/feature-flags')
    },
    getFeatureToggle(uuid) {
        return axios.get(`/features/feature-flags/${uuid}`)
    },
    updateFeatureToggleAccessLevel(body) {
        return axios.post('/features/feature-flags', body)
    },
    getFeatures(params) {
        return axios.get('/features', { params })
    },
    addFeature(body) {
        return axios.post('/features', body)
    },
    updateFeature(uuid, body) {
        return axios.put(`/features/${uuid}`, body)
    },
    getEnvironmentSettings() {
        return axios.get('/environment/settings')
    },
    updateEnvironmentSettings(body = {}) {
        return axios.patch('/environment/settings', body)
    },
    getSocialIdentities(uuid) {
        return axios.get(`/users/${uuid}/social-identities`)
    },
    deleteSocialIdentity(userUuid, providerName) {
        return axios.delete(`/users/${userUuid}/social-identities/${providerName}`)
    },
    updatePlanDisplayOrder(planUuid, body) {
        return axios.put(`/plans/display-order/${planUuid}`, body)
    },
}
