<template>
    <BaseCard
        class="attributes">
        <template #title>
            <i
                class="fas fa-cube text-primary mr-2"
                :aria-hidden="true" />
            Features
        </template>

        <template #header-link>
            <button
                class="btn btn-link"
                @click="() => showAddAttribute = true">
                <i
                    class="fas fa-plus mr-1"
                    :aria-hidden="true" />
                New Feature
            </button>
        </template>

        <template #body>
            <VueGoodTable
                :cols="columns"
                :rows="attributes"
                class-name="attributes__table">
                <template #table-row="props">
                    <span v-if="props.column.field === 'actions'">
                        <router-link
                            :to="{
                                name: 'PlanManagementEditAttribute',
                                params: {
                                    uuid: props.row.uuid
                                }
                            }"
                            class="btn btn-link">
                            <i
                                class="fas fa-edit mr-1"
                                :aria-hidden="true" />
                            Edit
                        </router-link>
                    </span>

                    <span v-else-if="props.column.field === 'type'">
                        {{ $readableTypes.get(props.row.type).label }}
                    </span>
                </template>
            </VueGoodTable>

            <ModalAddAttribute
                v-if="showAddAttribute"
                @click="() => showAddAttribute = false"
                @close="() => showAddAttribute = false"
                @reload="() => getAttributes()"></ModalAddAttribute>
        </template>
    </BaseCard>
</template>

<script>
import { BaseCard } from '@technologyadvice/relay-frontend'
import VueGoodTable from '@technologyadvice/ta-good-table'
import ModalAddAttribute from '@components/plans/ModalAddAttribute.vue'
import api from '@api/accounts'

export default {
    components: {
        BaseCard,
        VueGoodTable,
        ModalAddAttribute,
    },
    data() {
        return {
            attributes: [],
            isLoading: true,
            isError: false,
            showAddAttribute: false,
        }
    },
    computed: {
        columns() {
            return [
                {
                    label: 'Name',
                    field: 'name',
                },
                {
                    label: 'Type',
                    field: 'type',
                },
                {
                    label: 'Default Value',
                    field: 'value',
                },
                {
                    label: '',
                    field: 'actions',
                },
            ]
        },
    },
    created() {
        this.getAttributes()
    },
    methods: {
        getAttributes() {
            api.getAttributes()
                .then(({ data }) => {
                    this.attributes = data.data
                })
                .catch(() => {
                    this.isError = true
                })
                .finally(() => {
                    this.isLoading = false
                })
        },
    },
}
</script>
