import Vue from 'vue'
import { loadProgressBar } from 'axios-progress-bar'
import VTooltip from 'v-tooltip'
import { Globals, Toasters } from '@technologyadvice/relay-frontend'
import readableTypes from '@/plugins/readableTypes'
import { messages } from 'vee-validate/dist/locale/en.json'
import {
    ValidationObserver, ValidationProvider, extend, setInteractionMode,
} from 'vee-validate'
import * as rules from 'vee-validate/dist/rules'
import { createPinia, PiniaVuePlugin } from 'pinia'
import App from './App.vue'
import router from './router'
import { store } from './store'

Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)

setInteractionMode('aggressive')
// Vee-Validate Rules
Object.keys(rules).forEach(rule => {
    extend(rule, {
        ...rules[rule], // copies rule configuration
        message: messages[rule], // assign message
    });
})

Vue.use(VTooltip)
Vue.use(Globals)
Vue.use(Toasters)
Vue.use(readableTypes)
Vue.use(PiniaVuePlugin)

const pinia = createPinia()

Vue.config.productionTip = false

new Vue({
    router,
    store,
    pinia,
    render: h => h(App),
}).$mount('#app')

loadProgressBar()
