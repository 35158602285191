<template>
    <IntegrationCard
        integration-type="zoom_info"
        title="ZoomInfo">
        <template #logo>
            <img
                class="integration-card-header__logo"
                alt="ZoomInfo Logo"
                src="../../../assets/imgs/logo/zoominfo-logo-light.svg">
        </template>

        <template #title>
            ZoomInfo
        </template>

        <template #description>
            Get contact data for people at the accounts showing buyer
            intent for your products. Access and action intent signals from two intent
            ecosystems in a single platform. Identify, target, engage, and convert your
            high-intent and most valuable pipeline.
        </template>
    </IntegrationCard>
</template>
<script>
import IntegrationCard from '@components/integrations/IntegrationCard.vue';

export default {
    components: {
        IntegrationCard,
    },
}
</script>
