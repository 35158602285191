import axios from '@axios';

export default {
    getIntegrations(params = {}) {
        return axios.get('/integrations', {
            params,
        })
    },
    storeIntegration(data) {
        return axios.post('/integrations', data)
    },
    updateIntegrations(uuid, data) {
        return axios.put(`/integrations/${uuid}`, data)
    },
    syncData(payload) {
        return axios.post('/sync-data', payload);
    },
}
