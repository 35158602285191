export const env = () => {
    const host = window.location.hostname

    if ([ 'dev', 'v2.dev' ].some(e => host.startsWith(e))) {
        return 'development'
    }
    if ([ 'traction.technologyadvice.com', 'relay.cool', 'traction.advicebrands.com' ].some(e => host.startsWith(e))) {
        return 'production'
    }
    if ([ 'qa', 'qe' ].some(e => host.startsWith(e))) {
        // QE environment should not have recaptcha- this ensures the env variables don't change that.
        return null
    }
    return 'local'
}

export const recaptchaKeyProvider = () => {
    const environment = env()
    let key = null

    if (environment === 'development') {
        key = process.env.VUE_APP_RECAPTCHA_SITE_KEY_DEV
    } else if (environment === 'production') {
        key = process.env.VUE_APP_RECAPTCHA_SITE_KEY
    } else if (environment === 'local') {
        key = process.env.VUE_APP_RECAPTCHA_SITE_KEY_LOCAL
    }

    return key
}

export const recaptchaKey = recaptchaKeyProvider()
