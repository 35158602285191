<template>
    <div>
        <BaseCard>
            <template #title>
                <div>
                    <i
                        aria-hidden="true"
                        class="fas fa-key fa-md card__title-icon" />
                    {{ cardTitle }}
                </div>
            </template>
            <template #body>
                <div>
                    <FormPassword
                        :reset-password-user-uuid="resetPasswordUserUuid">
                    </FormPassword>
                </div>
            </template>
        </BaseCard>
    </div>
</template>

<script>
import FormPassword from '@components/common/FormPassword.vue'
import {
    BaseCard,
} from '@technologyadvice/relay-frontend'

export default {
    components: {
        BaseCard,
        FormPassword,
    },
    props: {
        cardTitle: {
            type: String,
            default: 'Change Password',
        },
        resetPasswordUserUuid: {
            type: String,
            default: null,
        },
    },
}
</script>
