import api from '@api/accounts' // eslint-disable-line
import Vue from 'vue'

export default {
    namespaced: true,
    state: {
        users: [],
        user: { },
        passwordMinCharacters: 12,
        loading: {
            user: true,
        },
        errors: {
            user: false,
        },
    },
    getters: {
        users: ({ users }) => users,
        user: ({ user }) => user,
        userByUuid: ({ users }) => user_uuid => users.find(user => user.uuid === user_uuid), // eslint-disable-line
        passwordMinCharacters: state => state.passwordMinCharacters,
        getLoadingState: state => name => state.loading[name],
        getErrorState: state => name => state.errors[name],
    },
    mutations: {
        addUser(state, payload) {
            state.users.push(payload)
        },
        deleteUserByUuid(state, { uuid }) {
            state.users = state.users.filter(user => user.uuid !== uuid)
        },
        setError(state, error) {
            Vue.set(state.errors, error.name, error.value)
        },
        setLoadingState(state, loading) {
            Vue.set(state.loading, loading.name, loading.value)
        },
        setUsers(state, payload) {
            state.users = payload
        },
        setUser(state, payload) {
            state.user = payload
        },
        updateUserByUuid(state, payload) {
            if (state.users.length) {
                Object.assign(state.users.find(user => user.uuid === payload.uuid), payload)
            }

            if (state.user.uuid && state.user.uuid === payload.uuid) {
                Object.assign(state.user, payload)
            }
        },
    },
    actions: {
        addUser({ commit }, payload) {
            commit('addUser', payload)
        },
        deleteUserByUuid({ commit }, payload) {
            commit('deleteUserByUuid', payload)
        },
        fetchUsers({ commit }) {
            commit('setLoadingState', { name: 'user', value: true })

            api.getUsers()
                .then(response => {
                    const { data } = response.data
                    commit('setUsers', data)
                })
                .catch(() => {
                    commit('setError', { name: 'user', value: true })
                })
                .finally(() => {
                    commit('setLoadingState', { name: 'user', value: false })
                })
        },
        loadUser({ commit }, userUuid) {
            commit('setLoadingState', { name: 'user', value: true })

            api.getUser(userUuid)
                .then(response => {
                    const { data } = response.data
                    commit('setUser', data.user)
                })
                .catch(() => {
                    commit('setError', { name: 'user', value: true })
                })
                .finally(() => {
                    commit('setLoadingState', { name: 'user', value: false })
                })
        },
        setUsers({ commit }, payload) {
            commit('setUsers', payload)
        },
        setUser({ commit }, payload) {
            commit('setUser', payload)
        },
        updateUserByUuid({ commit }, payload) {
            commit('updateUserByUuid', payload)
        },

    },
};
