<template>
    <ContextMenu placement="bottom-end">
        <template #button="{ toggle }">
            <IconButton
                class="shadow-none"
                icon="fa-ellipsis-v"
                label=""
                aria-label="Edit Integration"
                @click.prevent="toggle"></IconButton>
        </template>

        <ContextMenuSection>
            <ContextMenuItem @click.prevent="switchView()">
                View Settings
            </ContextMenuItem>
            <ContextMenuItem @click.prevent="disableIntegration()">
                Disable Integration
            </ContextMenuItem>
        </ContextMenuSection>
    </ContextMenu>
</template>

<script>
import {
    ContextMenu, ContextMenuSection, ContextMenuItem, IconButton,
} from '@technologyadvice/relay-frontend';

export default {
    components: {
        ContextMenu,
        ContextMenuSection,
        ContextMenuItem,
        IconButton,
    },
    methods: {
        switchView() {
            // hard-coding zoominfo while only integration type supported
            this.$router.push({ name: 'Settings', params: { type: 'zoominfo' } })
        },
        disableIntegration() {
            this.$emit('disable-integration-clicked')
        },
    },
}
</script>
