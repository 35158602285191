import { render, staticRenderFns } from "./CreateUserModal.vue?vue&type=template&id=277c7fa4"
import script from "./CreateUserModal.vue?vue&type=script&lang=js"
export * from "./CreateUserModal.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports