<template>
    <BaseModal
        modal-size="large"
        :scroll-content="false"
        @close="handleClose">
        <template #body>
            <div class="d-flex">
                <div class="create-user-modal__sidebar" />

                <div class="create-user-modal__body">
                    <ValidationObserver v-slot="{handleSubmit, invalid}">
                        <h5 class="pb-3">
                            Create User
                        </h5>

                        <div class="mb-3">
                            <label class="label-control card__info-label required">Email</label>

                            <p class="pb-3 ">
                                <small>
                                    TechnologyAdvice users will use "Sign In with Google" once their account is created,
                                    so please use their TechnologyAdvice Google email address.
                                </small>
                            </p>

                            <ValidationProvider
                                v-slot="{ errors }"
                                rules="required|email|unique-email|valid-email-domain"
                                name="email">
                                <input
                                    v-model="newUser.email"
                                    type="text"
                                    class="form-control"
                                    :class="{ 'is-invalid' : errors.length }"
                                    name="email">

                                <MessageValidationError
                                    v-if="errors.length"
                                    :msg="errors[0]">
                                </MessageValidationError>
                            </ValidationProvider>
                        </div>

                        <div class="mb-3">
                            <label class="label-control card__info-label">Job Title</label>

                            <input
                                v-model="newUser.job_title"
                                type="text"
                                class="form-control">
                        </div>

                        <div class="mb-3">
                            <label class="label-control card__info-label required">User Roles</label>

                            <ValidationProvider
                                v-slot="{ errors }"
                                rules="required"
                                name="user roles">
                                <Multiselect
                                    v-model="newUser.roles"
                                    :options="userRoles"
                                    :multiple="true"
                                    :show-labels="false"
                                    :hide-selected="true"
                                    :class="{ 'is-invalid' : errors.length }"
                                    open-direction="bottom"
                                    label="name"
                                    track-by="code"
                                    placeholder="Select Role(s)"
                                    name="roles">
                                </Multiselect>

                                <MessageValidationError
                                    v-if="errors.length"
                                    :msg="errors[0]">
                                </MessageValidationError>
                            </ValidationProvider>
                        </div>

                        <div class="create-user-modal__action-buttons">
                            <IconButton
                                class="btn-success"
                                icon="fa-plus"
                                label="Add User"
                                aria-label="Add User"
                                :loading="isSubmitting"
                                :disabled="invalid"
                                @click="handleSubmit(submit)"></IconButton>
                        </div>
                    </ValidationObserver>
                </div>
            </div>
        </template>
    </BaseModal>
</template>

<script>
import Multiselect from 'vue-multiselect'
import { mapGetters, mapActions } from 'vuex'
import api from '@api/accounts'
import { extend } from 'vee-validate'
import {
    IconButton,
    BaseModal,
    MessageValidationError,
} from '@technologyadvice/relay-frontend'

export default {
    components: {
        BaseModal,
        Multiselect,
        MessageValidationError,
        IconButton,
    },
    data() {
        return {
            isSubmitting: false,
            newUser: {
                email: '',
                job_title: '',
                roles: [],
            },
        }
    },
    computed: {
        ...mapGetters('auth', [
            'userRoles',
        ]),
        ...mapGetters('users', [
            'users',
        ]),
    },
    created() {
        this.initializeCustomValidationRules()
    },
    methods: {
        ...mapActions('users', [
            'addUser',
        ]),
        submit() {
            this.isSubmitting = true

            api.createUser({
                ...this.newUser,
                roles: this.newUser.roles.map(role => role.code),
            })
                .then(({ data }) => {
                    this.addUser({ ...data.data, roles: this.newUser.roles })
                    this.$_toasterSuccess('User created successfully!')
                    this.handleClose()
                })
                // inform user of error
                .catch(() => this.$_toasterDanger('Could not create user. Please try again or contact support.'))
                .finally(() => {
                    this.isSubmitting = false
                })
        },
        handleClose() {
            this.$emit('close')
        },
        initializeCustomValidationRules() {
            extend('unique-email', {
                validate: value => {
                    const userEmails = this.users.map(user => user.email.toLowerCase())
                    return !userEmails.includes(value.toLowerCase())
                },
                message: () => 'A user with this email already exists',
            })

            extend('valid-email-domain', {
                validate: value => {
                    const valueFormatted = value.toLowerCase()
                    return valueFormatted.includes('@technologyadvice.com') || valueFormatted.includes('@gmail.com')
                },
                message: () => 'The user\'s email must be a gmail.com or technologyAdvice.com address.',
            })
        },
    },
}
</script>
