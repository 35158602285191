<template>
    <BaseCard>
        <template #title>
            <div class="user_info_card">
                <i
                    aria-hidden="true"
                    class="fa fa-user fa-md card__title-icon" />
                User Info
            </div>
        </template>
        <template
            #header-link>
            <router-link
                v-scope:manage-users
                to="/users">
                <i
                    aria-hidden="true"
                    class="fa fa-chevron-left" />
                Back to Users
            </router-link>
        </template>
        <template #body>
            <ValidationObserver v-slot="{handleSubmit, invalid}">
                <div class="row">
                    <div class="mb-3 col-12 col-md-6">
                        <label class="label-control card__info-label">First Name</label>

                        <div>
                            <input
                                v-if="hasScope('external-user') || hasScope('external-client')"
                                v-model="profileUser.first_name"
                                type="text"
                                class="form-control">

                            <div v-else-if="profileUser.first_name">
                                {{ profileUser.first_name }}
                            </div>

                            <div v-else>
                                <em>Not provided</em>
                            </div>
                        </div>
                    </div>

                    <div class="mb-3 col-12 col-md-6">
                        <!-- add field to allow updates -->
                        <label class="label-control card__info-label">Last Name</label>

                        <div>
                            <input
                                v-if="hasScope('external-user') || hasScope('external-client')"
                                v-model="profileUser.last_name"
                                type="text"
                                class="form-control">

                            <div v-else-if="profileUser.last_name">
                                {{ profileUser.last_name }}
                            </div>

                            <div v-else>
                                <em>Not provided</em>
                            </div>
                        </div>
                    </div>

                    <div class="mb-3 col-12 col-md-6">
                        <label class="label-control card__info-label">Email</label>

                        <div>
                            {{ profileUser.email }}
                        </div>
                    </div>

                    <div class="mb-3 col-12 col-md-6">
                        <label class="label-control card__info-label mb-1">Job Title</label>

                        <input
                            v-if="canManageUser || hasScope('external-user') || hasScope('external-client')"
                            v-model="profileUser.job_title"
                            type="text"
                            class="form-control">

                        <div v-else-if="profileUser.job_title">
                            {{ profileUser.job_title }}
                        </div>

                        <div v-else>
                            <em>Not provided</em>
                        </div>
                    </div>

                    <div
                        v-if="!hasScope('external-user')"
                        class="mb-3 col-12 col-md-6">
                        <label class="label-control card__info-label">User Roles</label>

                        <ValidationProvider
                            v-if="canManageUser"
                            v-slot="{ errors }"
                            rules="required"
                            name="user roles">
                            <Multiselect
                                v-model="profileUser.roles"
                                :options="userRoles"
                                :multiple="true"
                                :show-labels="false"
                                :hide-selected="true"
                                :class="{ 'is-invalid' : errors.length }"
                                open-direction="bottom"
                                label="name"
                                track-by="code"
                                placeholder="Select Role(s)"
                                name="roles">
                            </Multiselect>
                            <MessageValidationError
                                v-if="errors.length"
                                :msg="errors[0]">
                            </MessageValidationError>
                        </ValidationProvider>

                        <div v-else>
                            <span
                                v-for="(role, roleIndex) in profileUser.roles"
                                :key="roleIndex">
                                <span v-if="roleIndex != 0">, </span><span>{{ role.name }}</span>
                            </span>
                        </div>
                    </div>
                    <div
                        v-if="profileUser.account"
                        class="mb-3 col-12 col-md-6">
                        <label class="label-control card__info-label">Company / Brand / Client</label>

                        <div>
                            {{ profileUser.account }}
                        </div>
                    </div>
                </div>

                <div
                    v-if="canManageUser || hasScope('external-user') || hasScope('external-client')"
                    class="d-flex flex-row-reverse">
                    <IconButton
                        class="btn-success"
                        icon="fa-save"
                        label="Update"
                        :loading="isSubmitting"
                        :disabled="invalid"
                        @click="handleSubmit(handleUpdateUser)"></IconButton>
                </div>
            </ValidationObserver>
        </template>
    </BaseCard>
</template>

<script>
import Multiselect from 'vue-multiselect'
import { mapGetters } from 'vuex'
import api from '@api/accounts'

import {
    BaseCard,
    IconButton,
    MessageValidationError,
} from '@technologyadvice/relay-frontend'

export default {
    components: {
        BaseCard,
        Multiselect,
        IconButton,
        MessageValidationError,
    },
    props: {
        value: {
            type: Object,
            default: null,
        },
        canManageUser: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            isSubmitting: false,
            profileUser: { ...this.value },
        }
    },
    computed: {
        ...mapGetters('auth', [
            'userRoles',
            'hasScope',
        ]),
    },
    watch: {
        value: {
            handler() {
                this.profileUser = { ...this.value }
            },
            deep: true,
        },
    },
    methods: {
        handleUpdateUser() {
            this.isSubmitting = true
            const {
                uuid, roles, first_name: firstName, last_name: lastName, job_title: jobTitle,
            } = this.profileUser

            api.updateUser({
                uuid,
                roles: roles.map(role => role.code),
                first_name: firstName.trim(),
                last_name: lastName.trim(),
                job_title: jobTitle.trim(),
            })
                .then(() => this.$_toasterSuccess('User updated successfully!'))
                .catch(() => this.$_toasterDanger('User could not be updated. Please try again or contact support.'))
                .finally(() => {
                    this.isSubmitting = false
                })
        },
    },
}
</script>
