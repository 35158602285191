<template>
    <ValidationObserver v-slot="{handleSubmit, invalid}">
        <BaseModal
            class="modal-plan-attribute"
            modal-size="medium"
            @close="close">
            <template #header>
                <div class="d-flex align-items-center h5 mb-0">
                    <i
                        class="fas fa-plus-circle text-primary mr-2"
                        :aria-hidden="true" />
                    {{ planAttribute ? "Edit Feature" : "Add Feature" }}
                </div>
            </template>

            <template #body>
                <div class="row">
                    <ValidationProvider
                        v-if="!planAttribute"
                        v-slot="{errors}"
                        tag="div"
                        rules="required"
                        name="feature"
                        class="col-12 mb-3">
                        <label class="card__info-label required">
                            Feature
                        </label>

                        <Multiselect
                            v-model="selectedAttribute"
                            :options="attributes"
                            :close-on-select="true"
                            :clear-on-select="false"
                            :show-labels="false"
                            :class="{'is-invalid': errors && errors.length}"
                            placeholder="Select a feature"
                            label="name"
                            track-by="uuid"
                            @select="selectedAttributeChange">
                        </Multiselect>

                        <MessageValidationError
                            v-if="errors && errors.length"
                            :msg="errors[0]">
                        </MessageValidationError>

                        <transition name="slide-toggle">
                            <div
                                v-if="selectedAttribute"
                                class="p-2">
                                {{ selectedAttribute.description }}
                            </div>
                        </transition>
                    </ValidationProvider>

                    <div
                        v-if="attribute.type && attribute.type !== 'solution_categories'"
                        class="col-12 mb-3">
                        <label class="card__info-label">
                            Value
                        </label>

                        <div v-if="attribute.type === 'boolean'">
                            <OptionBoxes
                                inline
                                type="radio"
                                :options="trueFalseOptions"
                                @change="selectTrueFalseOptions">
                            </OptionBoxes>
                        </div>

                        <div v-else>
                            <input
                                v-model="attribute.value"
                                class="form-control">
                        </div>
                    </div>
                </div>
            </template>

            <template #footer>
                <div class="container-fluid mx-0">
                    <div class="d-flex justify-content-between">
                        <button
                            class="btn btn-link text-secondary"
                            @click="close">
                            <i
                                class="fas fa-times mr-1"
                                :aria-hidden="true" />
                            Cancel
                        </button>

                        <IconButton
                            class="btn-primary"
                            icon="fa-save"
                            :label="planAttribute ? 'Update' : 'Create'"
                            :loading="isSaving"
                            :disabled="invalid"
                            @click="handleSubmit(() => alert({isCreate: !planAttribute}))"></IconButton>
                    </div>
                </div>
            </template>
        </BaseModal>
    </ValidationObserver>
</template>

<script>
import {
    BaseModal, MessageValidationError, OptionBoxes, IconButton,
} from '@technologyadvice/relay-frontend'
import api from '@api/accounts'
import Multiselect from 'vue-multiselect'
import Swal from 'sweetalert2'

export default {
    components: {
        BaseModal,
        OptionBoxes,
        Multiselect,
        MessageValidationError,
        IconButton,
    },
    props: {
        planAttribute: {
            type: Object,
            required: false,
            default: () => ({}),
        },
        planUuid: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            isSaving: false,
            selectedAttribute: null,
            attributes: [],
            attribute: {},
            trueFalseOptions: [
                {
                    label: 'True',
                    property: 'true',
                    enabled: false,
                },
                {
                    label: 'False',
                    property: 'false',
                    enabled: false,
                },
            ],
        }
    },
    computed: {
        types() {
            return this.$readableTypes.all()
        },
    },
    mounted() {
        if (this.planAttribute) {
            this.initializeAttribute()
        } else {
            this.getAttributes()
        }
    },
    methods: {
        initializeAttribute() {
            this.attribute = {
                ...this.planAttribute,
            }

            this.trueFalseOptions = this.trueFalseOptions.map(option => ({
                label: option.label,
                property: option.property,
                enabled: this.attribute.value === option.property,
            }))
        },
        getAttributes() {
            api.getAttributes().then(({ data }) => {
                this.attributes = data.data
            })
        },
        selectTrueFalseOptions(value) {
            this.trueFalseOptions = this.trueFalseOptions.map(option => ({
                label: option.label,
                property: option.property,
                enabled: value[option.property],
            }))

            const { property } = this.trueFalseOptions.find(({ enabled }) => enabled)

            this.attribute.value = property
        },
        close() {
            this.$emit('close')
        },
        alert({ isCreate }) {
            this.isSaving = true

            Swal.fire({
                title: `Are you sure you want to ${isCreate ? 'add' : 'update'} this feature?`,
                text: `This will apply to all existing plans. 
                    If an account has this feature overriden, the override will remain.`,
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: `${isCreate ? 'Add' : 'Update'} Feature`,
                reverseButtons: true,
            })
                .then(result => {
                    if (result.value && isCreate) {
                        this.create()
                    } else if (result.value && !isCreate) {
                        this.update()
                    }
                })
                .finally(() => {
                    this.isSaving = false
                })
        },
        create() {
            return api.storePlanAttribute({
                plan_uuid: this.planUuid,
                attribute_uuid: this.attribute.uuid,
                value: this.attribute.value,
            })
                .then(() => {
                    this.$_toasterSuccess('Successfully created a new feature')

                    this.$emit('reload')
                    this.close()
                })
                .catch(() => {
                    this.$_toasterDanger('Failed to create a new feature')
                })
        },
        update() {
            return api.updatePlanAttribute(this.attribute.uuid, {
                value: this.attribute.value,
            })
                .then(() => {
                    this.$_toasterSuccess('Successfully updated feature')

                    this.$emit('reload')
                    this.close()
                })
                .catch(() => {
                    this.$_toasterDanger('Failed to update feature')
                })
        },
        selectedAttributeChange(value) {
            this.attribute = { ...value }
        },
    },
}
</script>
