<template>
    <ValidationObserver v-slot="{handleSubmit, invalid}">
        <div class="row form-password">
            <div class="col-12 col-md-6">
                <div class="mb-3">
                    <label class="card__info-label required">New Password</label>

                    <ValidationProvider
                        v-slot="{ errors }"
                        rules="required"
                        name="password">
                        <input
                            ref="password"
                            v-model="password"
                            name="password"
                            type="password"
                            :class="{ 'is-invalid' : errors.length }"
                            class="form-control">

                        <MessageValidationError
                            v-if="errors.length"
                            :msg="errors[0]">
                        </MessageValidationError>
                    </ValidationProvider>
                </div>

                <div class="mb-3">
                    <label class="card__info-label required">Confirm New Password</label>

                    <ValidationProvider
                        v-slot="{ errors }"
                        rules="required|confirmed:password"
                        name="confirm new password">
                        <input
                            v-model="password_confirmation"
                            name="confirm password"
                            type="password"
                            :class="{ 'is-invalid' : errors.length }"
                            class="form-control">

                        <MessageValidationError
                            v-if="errors.length"
                            :msg="errors[0]">
                        </MessageValidationError>
                    </ValidationProvider>
                </div>
            </div>

            <div class="col-12 col-md-6">
                <h6 class="mb-3">
                    Password must meet the following requirements:
                </h6>

                <div class="mb-2">
                    <i
                        aria-hidden="true"
                        :class="{
                            'fas fa-check-circle text-success' : hasUppercaseLetter,
                            'far fa-circle ': !hasUppercaseLetter,
                        }"
                        class="mr-2" />

                    <span
                        :class="{
                            'text-success': hasUppercaseLetter
                        }">
                        At least one uppercase letter
                    </span>
                </div>

                <div class="mb-2">
                    <i
                        aria-hidden="true"
                        :class="{
                            'fas fa-check-circle text-success' : hasLowercaseLetter,
                            'far fa-circle ': !hasLowercaseLetter,
                        }"
                        class="mr-2" />

                    <span
                        :class="{
                            'text-success': hasLowercaseLetter
                        }">
                        At least one lowercase letter
                    </span>
                </div>

                <div class="mb-2">
                    <i
                        aria-hidden="true"
                        :class="{
                            'fas fa-check-circle text-success' : hasNumber,
                            'far fa-circle ': !hasNumber,
                        }"
                        class="mr-2" />

                    <span
                        :class="{
                            'text-success': hasNumber
                        }">
                        At least one number (0-9)
                    </span>
                </div>

                <div class="mb-2">
                    <i
                        aria-hidden="true"
                        :class="{
                            'fas fa-check-circle text-success' : hasMinCharacters,
                            'far fa-circle ': !hasMinCharacters,
                        }"
                        class="mr-2" />

                    <span
                        :class="{
                            'text-success': hasMinCharacters
                        }">
                        At least {{ passwordMinCharacters }} characters
                    </span>
                </div>

                <div class="mb-2">
                    <i
                        aria-hidden="true"
                        :class="{
                            'fas fa-check-circle text-success' : doesNotContainSpaces,
                            'far fa-circle ': !doesNotContainSpaces,
                        }"
                        class="mr-2" />

                    <span
                        :class="{
                            'text-success': doesNotContainSpaces
                        }">
                        Does not contain spaces
                    </span>
                </div>

                <div class="mb-2">
                    <i
                        aria-hidden="true"
                        :class="{
                            'fas fa-check-circle text-success' : confirmMatchesPassword,
                            'far fa-circle ': !confirmMatchesPassword,
                        }"
                        class="mr-2" />

                    <span
                        :class="{
                            'text-success': confirmMatchesPassword
                        }">
                        Passwords must match
                    </span>
                </div>

                <InvisibleRecaptcha
                    v-if="redirectOnSubmit"
                    ref="recaptcha"
                    @verified="verifiedRecaptcha"
                    @challengeCancelled="resetLoading"></InvisibleRecaptcha>
            </div>

            <div
                :class="{
                    'justify-content-end' : !redirectOnSubmit,
                }"
                class="col-12 d-flex mt-3">
                <IconButton
                    :class="{
                        'btn-success' : !redirectOnSubmit,
                        'btn-primary py-2' : redirectOnSubmit
                    }"
                    class="px-3"
                    :icon="redirectOnSubmit ? 'fa-arrow-right' : 'fa-key'"
                    :label="submitDisplayText"
                    aria-label="Add Brand"
                    :loading="loading"
                    :disabled="invalid || !submitEnabled"
                    @click="handleSubmit(submit)"></IconButton>
            </div>
        </div>
    </ValidationObserver>
</template>

<script>

import InvisibleRecaptcha from '@components/ui/InvisibleRecaptcha.vue'
import api from '@api/accounts'
import { mapGetters, mapActions } from 'vuex'
import {
    IconButton,
    MessageValidationError,
} from '@technologyadvice/relay-frontend'

export default {
    components: {
        InvisibleRecaptcha,
        MessageValidationError,
        IconButton,
    },
    props: {
        redirectOnSubmit: {
            type: Boolean,
            required: false,
            default: false,
        },
        resetPasswordUserUuid: {
            type: String,
            default: null,
        },
        callback: {
            type: Function,
            default: null,
        },
        submitText: {
            type: String,
            default: null,
        },
    },
    data() {
        return {
            password: '',
            password_confirmation: '',
            loading: false,
            isUser: false,
            recaptcha: '',
        }
    },
    computed: {
        ...mapGetters('users', {
            userBeingEdited: 'user',
            passwordMinCharacters: 'passwordMinCharacters',
        }),
        ...mapGetters('auth', [
            'user',
        ]),
        hasUppercaseLetter() {
            return !!this.password.match(/[A-Z]/g)
        },
        hasLowercaseLetter() {
            return !!this.password.match(/[a-z]/g)
        },
        hasNumber() {
            return !!this.password.match(/[0-9]/g)
        },
        hasMinCharacters() {
            return this.password.length >= this.passwordMinCharacters
        },
        doesNotContainSpaces() {
            return this.password.length && !this.password.match(/\s/g)
        },
        confirmMatchesPassword() {
            return this.password.length > 0 && this.password === this.password_confirmation
        },
        submitEnabled() {
            return !this.loading
                && this.confirmMatchesPassword
                && this.hasUppercaseLetter
                && this.hasLowercaseLetter
                && this.hasNumber
                && this.hasMinCharacters
                && this.doesNotContainSpaces
        },
        submitDisplayText() {
            if (this.submitText) return this.submitText

            return this.redirectOnSubmit
                ? 'Set Password & Continue'
                : 'Update Password'
        },
    },
    methods: {
        ...mapActions('auth', [
            'login',
            'logout',
        ]),
        submit() {
            this.loading = true
            // If redirectOnSubmit, execute recaptcha since we'll need the token for login
            if (this.redirectOnSubmit) {
                this.$refs.recaptcha.execute()
                this.loading = false
            } else {
                const next = this.callback || this.updateUserPassword
                next({
                    password: this.password,
                    password_confirmation: this.password_confirmation,
                }).finally(() => {
                    this.loading = false
                })
            }
        },
        updateUserPassword() {
            this.loading = true
            return this.sendPasswordRequest()
                .then(() => {
                    // handle redirect on submit
                    if (this.redirectOnSubmit) {
                        this.login({
                            email: this.$route.query.email,
                            password: this.password,
                            recaptcha: this.recaptcha,
                            redirect: '',
                        })
                            .then(() => {
                                this.$router.push({ path: '/' })
                            })
                    }

                    this.$_toasterSuccess('Updated successfully!')
                    // if resetting password, force logout to get new auth
                    if (this.isUser && this.resetPasswordUserUuid) {
                        this.logoutUser()
                    }
                })
                .catch(() => {
                    this.$_toasterDanger('Update failed. Please try again.')
                })
                .finally(() => {
                    this.loading = false
                })
        },
        logoutUser() {
            this.logout()
                .then(() => {
                    window.location = '/accounts/login'
                })
        },
        sendPasswordRequest() {
            if (this.redirectOnSubmit) {
                return api.resetUserPassword({
                    email: this.$route.query.email,
                    token: this.$route.params.token,
                    password: this.password,
                    password_confirmation: this.password_confirmation,
                })
            }

            const userUuid = this.resetPasswordUserUuid
                ? this.resetPasswordUserUuid
                : (this.$router.currentRoute.params.user_uuid || this.user.uuid)

            this.isUser = this.user.uuid === userUuid

            const updateParams = {
                uuid: userUuid,
                password: this.password,
                password_confirmation: this.password_confirmation,
            }

            // if resetPasswordUserUuid is present, we are sending false since this
            // is only when the user is redirected to the force password reset page
            // if it is from a different page
            // we only send true if it is an teledemand agent and the user is not editing their own password
            updateParams.force_password_change = this.resetPasswordUserUuid
                ? false
                : this.userBeingEdited.roles.find(role => role.code === 'teledemand_agent') && !this.isUser

            return api.updateUser(updateParams)
        },
        verifiedRecaptcha(recaptchaToken) {
            this.recaptcha = recaptchaToken

            this.updateUserPassword()
        },
        resetLoading() {
            this.loading = false
        },
    },
}
</script>
