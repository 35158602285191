<template>
    <BaseCard
        :is-loading="isLoading"
        class="plans">
        <template #title>
            <i
                class="fas fa-cubes text-primary mr-2"
                :aria-hidden="true" />
            Plans
        </template>

        <template #header-link>
            <button
                class="btn btn-link"
                @click="() => showModalAddPlan = true">
                <i
                    class="fas fa-plus mr-1"
                    :aria-hidden="true" />
                New Plan
            </button>
        </template>

        <template #body>
            <VueGoodTable
                :cols="columns"
                :rows="plans"
                class-name="plans__table"
                :sort-options="{
                    enabled: true,
                    initialSortBy: {field: 'display_order', type: 'asc'}
                }">
                <template #table-row="props">
                    <span v-if="props.column.field === 'clients_on_plan'">
                        <a
                            :href="`/client/clients/list?plan=${props.row.name}`"
                            tag="button">
                            {{ props.row.clients_on_plan }}
                        </a>
                    </span>
                    <span v-else-if="props.column.field === 'is_active'">
                        <ToggleButton
                            :id="'planIndexToggle-' + props.row.uuid"
                            v-model="props.row.is_active"
                            @input="e => updatePlanStatus(props.row)">
                        </ToggleButton>
                    </span>
                    <span v-else-if="props.column.field === 'actions'">
                        <router-link
                            :to="{
                                name: 'PlanManagementEditPlan',
                                params: {
                                    uuid: props.row.uuid
                                }
                            }"
                            tag="button"
                            class="btn btn-link"
                            :disabled="isSwappingPlanOrders">
                            <i
                                class="fas fa-edit mr-1"
                                :aria-hidden="true" />
                            Edit
                        </router-link>

                        <button
                            v-tooltip="'Move plan up'"
                            class="btn btn-primary-transparent ml-4 mr-2 plans__button"
                            :disabled="props.row.display_order == 1 || isSwappingPlanOrders"
                            @click="updatePlanDisplayOrder(props.row.uuid, (props.row.display_order - 1))">
                            <i
                                v-if="!isSwappingPlanOrders"
                                aria-hidden="true"
                                class="fas fa-angle-up" />
                            <i
                                v-else
                                aria-hidden="true"
                                class="fas fa-spinner fa-spin" />
                        </button>

                        <button
                            v-tooltip="'Move plan down'"
                            class="btn btn-primary-transparent mr-2 plans__button"
                            :disabled="props.row.display_order == plans.length || isSwappingPlanOrders"
                            @click="updatePlanDisplayOrder(props.row.uuid, (props.row.display_order + 1))">
                            <i
                                v-if="!isSwappingPlanOrders"
                                aria-hidden="true"
                                class="fas fa-angle-down" />
                            <i
                                v-else
                                aria-hidden="true"
                                class="fas fa-spinner fa-spin" />
                        </button>

                    </span>
                </template>
            </VueGoodTable>

            <ModalAddPlan
                v-if="showModalAddPlan"
                @reload="getPlans"
                @close="() => showModalAddPlan = false"></ModalAddPlan>
        </template>
    </BaseCard>
</template>

<script>
import { BaseCard, ToggleButton } from '@technologyadvice/relay-frontend'
import ModalAddPlan from '@components/plans/ModalAddPlan.vue'
import VueGoodTable from '@technologyadvice/ta-good-table'
import api from '@api/accounts'
import Swal from 'sweetalert2'

export default {
    components: {
        BaseCard,
        VueGoodTable,
        ModalAddPlan,
        ToggleButton,
    },
    data() {
        return {
            plans: [],
            isLoading: true,
            isSwappingPlanOrders: false,
            isError: false,
            showModalAddPlan: false,
        }
    },
    computed: {
        columns() {
            return [
                {
                    label: '',
                    field: 'display_order',
                    type: 'number',
                    sortable: false,
                },
                {
                    label: 'Name',
                    field: 'name',
                },
                {
                    label: 'Description',
                    field: 'description',
                },
                {
                    label: '# of Clients',
                    field: 'clients_on_plan',
                },
                {
                    label: 'Active',
                    field: 'is_active',
                    sortable: false,
                },
                {
                    label: '',
                    field: 'actions',
                },
            ]
        },
    },
    created() {
        this.getPlans()
    },
    methods: {
        updatePlanStatus(plan) {
            Swal.fire({
                title: `Are you sure you want to ${plan.is_active ? 'activate' : 'deactivate'} the ${plan.name} plan?`,
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes',
                reverseButtons: true,
            })
                .then(result => {
                    const newStatus = { is_active: plan.is_active }

                    if (result.value) {
                        api.updatePlan(plan.uuid, newStatus)
                            .then(() => {
                                this.$_toasterSuccess(
                                    `${plan.name} plan is now ${plan.is_active ? 'active' : 'inactive'}`,
                                )
                            })
                            .catch(() => {
                                this.$_toasterDanger(`Failed to update the ${plan.name} plan status`)
                            })
                    } else {
                        plan.is_active = !plan.is_active
                    }
                })
        },
        getPlans() {
            api.getPlans()
                .then(({ data }) => {
                    this.plans = data.data
                })
                .catch(() => {
                    this.isError = true
                })
                .finally(() => {
                    this.isLoading = false
                    this.isSwappingPlanOrders = false;
                })
        },
        updatePlanDisplayOrder(uuid, newDisplayOrder) {
            this.isSwappingPlanOrders = true;

            return api.updatePlanDisplayOrder(uuid, {
                new_display_order: newDisplayOrder,
            })
                .then(() => this.$_toasterSuccess('Plan order updated'))
                .catch(() => this.$_toasterDanger('Update failed. Please try again.'))
                .finally(() => this.getPlans())
        },
    },
}
</script>
