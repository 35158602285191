<template>
    <transition name="slide-toggle">
        <div>
            <div class="integration-card-border">
                <div class="d-flex flex-column justify-content-between h-100">
                    <div
                        class="
                            integration-card-header
                            d-flex
                            py-3
                            px-4
                            justify-content-between
                            align-items-center
                            flex-wrap">
                        <slot name="logo" />
                        <div
                            v-if="integrationEnabled"
                            class="d-flex flex-row">
                            <IntegrationStatusIndicator
                                :connected="true">
                            </IntegrationStatusIndicator>
                            <IntegrationContextMenu
                                @disable-integration-clicked="disableIntegrationEvent">
                            </IntegrationContextMenu>
                        </div>

                        <router-link
                            v-else
                            :to="{
                                name: 'IntegrationDetails',
                                params: {
                                    type: 'zoominfo'
                                }
                            }"
                            tag="button"
                            class="btn btn-primary">
                            Details
                        </router-link>
                    </div>
                    <div class="integration-card-body pt-3 px-4">
                        <h2 class="h4">
                            <slot name="title" />
                        </h2>
                        <p>
                            <slot name="description" />
                        </p>
                    </div>
                </div>
            </div>

            <ModalConfirmation
                v-if="openModal"
                @disable-integration="disableIntegrationEvent"
                @close="toggleModal">
                <template #modal-body>
                    Are you sure you want to disable "{{ title }}" Integration?
                </template>
            </ModalConfirmation>
        </div>
    </transition>
</template>

<script>
import IntegrationStatusIndicator from '@components/integrations/IntegrationStatusIndicator.vue'
import IntegrationContextMenu from '@components/integrations/IntegrationContextMenu.vue'
import ModalConfirmation from '@components/ui/ModalConfirmation.vue'
import { useIntegrationsStore } from '@store/integrations/index'
import { mapState } from 'pinia'
import api from '@api/integrations'

export default {
    components: {
        IntegrationContextMenu,
        IntegrationStatusIndicator,
        ModalConfirmation,
    },
    data() {
        return {
            openModal: false,
        }
    },
    props: {
        integrationType: {
            type: String,
            default: '',
        },
        categories: {
            type: Array,
            default: () => [],
        },
        title: {
            type: String,
            default: '',
        },
    },
    computed: {
        ...mapState(useIntegrationsStore, [ 'integrations' ]),
        integration() {
            return this.integrations.find(integration => integration.type === this.integrationType)
        },
        integrationEnabled() {
            return this.integration?.enabled === true
        },
    },
    methods: {
        disableIntegrationEvent(confirmation = false) {
            if (confirmation) {
                api.updateIntegrations(this.integration.id, { enabled: false })
                    .then(({ data }) => {
                        this.$_toasterSuccess(`Successfully disabled ${this.title} integration`)
                        useIntegrationsStore().$patch(state => {
                            const index = state.integrations.findIndex(integration => integration.id === data.data.id)

                            if (index !== -1) {
                                state.integrations[index].enabled = data.data.enabled
                            }
                        })
                    })
                    .catch(() => {
                        this.$_toasterDanger(
                            'Integration settings could not be updated. Please try again or contact support.',
                        )
                    })
            }
            this.toggleModal()
        },
        toggleModal() {
            this.openModal = !this.openModal
        },
    },
}
</script>
