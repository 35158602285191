<template>
    <div>
        <div class="d-flex justify-content-between">
            <ViewHeader
                class="mb-4"
                heading="User Management: Employees"
                sub-heading="Manage the accounts and roles for your team">
            </ViewHeader>
            <div class="mt-3">
                <div
                    class="btn btn-primary"
                    @click="toggleCreateUserModal">
                    <i
                        aria-hidden="true"
                        class="fas fa-plus" /> Create New User
                </div>
            </div>
        </div>

        <ListUsers></ListUsers>

        <transition name="fade">
            <div
                v-if="showCreateUserModal"
                class="create-user-modal">
                <CreateUserModal
                    @close="toggleCreateUserModal">
                </CreateUserModal>
            </div>
        </transition>
    </div>
</template>

<script>
import CreateUserModal from '@components/users/CreateUserModal.vue'
import ListUsers from '@components/users/ListUsers.vue'
import {
    ViewHeader,
} from '@technologyadvice/relay-frontend'

export default {
    name: 'Users',
    components: {
        CreateUserModal,
        ListUsers,
        ViewHeader,
    },
    data() {
        return {
            showCreateUserModal: false,
        }
    },
    methods: {
        toggleCreateUserModal() {
            this.showCreateUserModal = !this.showCreateUserModal
        },
    },
}
</script>
