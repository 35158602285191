import { defineStore } from 'pinia'
import api from '@api/integrations'

export const useIntegrationsStore = defineStore('integrations', {
    state: () => ({
        integrations: [],
        initialized: false,
        loading: false,
        error: false,
    }),
    actions: {
        load(force = false) {
            if (this.initialized && !force) return

            this.error = false
            this.loading = true

            api.getIntegrations()
                .then(({ data }) => { this.integrations = data.data ?? [] })
                .catch(() => { this.error = true })
                .finally(() => {
                    this.loading = false
                    this.initialized = true
                })
        },
    },
})
