<template>
    <div
        class="container-fluid h-100 loginV2__bkg">
        <div class="d-flex justify-content-center align-items-center h-100">
            <div class="join-account">
                <div class="mb-4">
                    <img
                        alt="Traction Logo"
                        :src="logo"
                        class="loginV2__logo">
                </div>

                <div class="loginV2__container join-account__card">
                    <div v-if="!error">
                        <h3 class="h5">
                            <i
                                :aria-hidden="true"
                                class="fas fa-check-circle text-success" />
                            Thank you, {{ user.first_name }}, your credentials have been validated.
                        </h3>

                        <p>
                            We are adding you to your new account now.
                            Redirecting you home in {{ redirectCountdown }} seconds.
                        </p>
                    </div>

                    <div v-else>
                        <h3 class="h5">
                            <i
                                :aria-hidden="true"
                                class="fas fa-times-circle text-danger" />
                            Failed
                        </h3>

                        <p>
                            We encountered an error during the request. You may refresh the page to try again. <br>
                            If you continue to experience issues, please reach out us at <SupportEmail></SupportEmail>.
                        </p>

                        <router-link
                            :to="{ path: '/'}">
                            <i
                                :aria-hidden="true"
                                class="fas fa-home mr-1" />
                            Navigate Home
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import logo from '@technologyadvice/relay-frontend/src/assets/brand-logo.svg'
import SupportEmail from '@components/common/SupportEmail.vue'
import { mapGetters } from 'vuex'
import api from '@api/accounts'
import authApi from '@api/auth'

export default {
    components: {
        SupportEmail,
    },
    data() {
        return {
            logo,
            error: false,
            redirectCountdown: 0,
        }
    },
    computed: {
        ...mapGetters('auth', [
            'user',
        ]),
    },
    created() {
        if (this.user) {
            this.acceptAccountInvite()
        } else {
            this.error = true
        }
    },
    methods: {
        acceptAccountInvite() {
            const accountUuid = this.$route.params.account_uuid

            api.joinAccount(this.user.uuid, { account_uuid: accountUuid })
                .then(response => {
                    if (response.status === 200) {
                        this.redirectCountdown = 3

                        const timerId = setInterval(() => {
                            this.redirectCountdown--

                            if (this.redirectCountdown === 0) {
                                authApi.switchAccount(this.user.uuid, accountUuid)
                                    .then(() => { window.location = '/' })

                                clearInterval(timerId)
                            }
                        }, 1000)
                    }
                })
                .catch(() => {
                    this.error = true
                })
        },
    },
}
</script>
