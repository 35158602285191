var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function({handleSubmit, invalid}){return [_c('div',{staticClass:"form-group"},[(_vm.fromLink)?_c('label',{staticClass:"card__info-label required mt-3"},[_vm._v(" Email ")]):_vm._e(),_c('ValidationProvider',{attrs:{"rules":"required|email|external-email","name":"email"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.email),expression:"email"}],staticClass:"form-control",class:{
                        'loginV2__input': !_vm.fromLink,
                        'is-invalid': errors.length && _vm.fromLink
                    },attrs:{"placeholder":"Email","name":"email","type":"text"},domProps:{"value":(_vm.email)},on:{"input":function($event){if($event.target.composing)return;_vm.email=$event.target.value}}}),(errors.length)?_c('MessageValidationError',{attrs:{"msg":errors[0]}}):_vm._e()]}}],null,true)})],1),_c('div',{staticClass:"mt-4"},[_c('IconButton',{staticClass:"btn-primary forgot-password__reset-password-btn px-3",class:{
                    'btn-block py-3 my-3': !_vm.fromLink,
                    'py-2' : _vm.fromLink
                },attrs:{"icon":"fa-envelope","label":"Send Reset Link","loading":_vm.isSubmitting,"disabled":invalid},on:{"click":function($event){return handleSubmit(_vm.sendResetEmail)}}}),_c('router-link',{class:{
                    'loginV2__reset-password-link': !_vm.fromLink,
                    'btn btn-secondary-transparent ml-2 py-2': _vm.fromLink
                },attrs:{"to":{name: 'loginV2'}}},[_c('div',{staticClass:"text-center"},[_vm._v(" Cancel ")])])],1)]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }