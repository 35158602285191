<template>
    <BaseCard class="card-social-identities">
        <template #title>
            <div>
                <Icon
                    icon="fa-user-shield"
                    size="md"
                    class="card__title-icon"></Icon>
                Social Identities
            </div>
        </template>

        <template #body>
            <div class="mb-2">
                Manage applications linked to your account for authentication.
            </div>

            <div
                v-for="(identity, index) in userIdentities"
                :key="index"
                :class="{
                    'border-top': index > 0,
                }"
                class="py-2">
                <div class="d-flex align-items-center">
                    <div class="col px-0 mr-auto w-75">
                        <div>
                            {{ identity.provider_name | capitalize }}
                            <Icon
                                v-if="identity.linked"
                                icon="fa-check-circle"
                                class="text-success"></Icon>
                        </div>
                        <div class="user-handle card__info-label text-truncate w-75">
                            {{ identity.user_email || 'Not Setup' }}
                        </div>
                    </div>

                    <button
                        v-if="identity.linked"
                        class="btn btn-outline-danger link-btn ml-auto"
                        :disabled="!isViewedUser"
                        @click="unlinkIdentity(identity)">
                        Unlink
                    </button>
                    <!-- to-do: will need to be updated to accept a target auth provider once multiple are supported -->
                    <a
                        v-else
                        :href="`/accounts/bff/redirect?${redirectQueryParam}`"
                        class="btn btn-success link-btn ml-auto"
                        :class="{ 'disable-btn': !isViewedUser }">
                        Link
                    </a>
                </div>
            </div>
        </template>
    </BaseCard>
</template>

<script>
import { BaseCard, Icon } from '@technologyadvice/relay-frontend'
import api from '@api/accounts'

export default {
    components: {
        BaseCard,
        Icon,
    },
    props: {
        userUuid: {
            type: String,
            required: true,
        },
        isViewedUser: {
            type: Boolean,
            required: true,
        },
    },
    data() {
        return {
            // Only Google supported for now - update to fetch via endpoint if additional providers added later
            supportedProviders: [ 'google' ],
            userIdentities: [],
        }
    },
    computed: {
        redirectQueryParam() {
            return `redirect=/accounts/users/${this.userUuid}`
        },
    },
    created() {
        this.initializeData()
    },
    methods: {
        initializeData() {
            api.getSocialIdentities(this.userUuid)
                .then(response => {
                    const socialIdentities = response.data.data
                    this.setUserIdentities(socialIdentities)
                })
                .catch(() => this.$_toasterDanger(`Could not load user's social identities.
                Please refresh or contact support.`))
        },
        setUserIdentities(socialIdentities) {
            this.userIdentities = this.supportedProviders.map(provider => {
                const found = socialIdentities.find(identity => identity.provider_name === provider)
                return {
                    provider_name: provider,
                    linked: !!found,
                    user_email: found?.user_email || null,
                }
            })
        },
        unlinkIdentity(provider) {
            api.deleteSocialIdentity(this.userUuid, provider.provider_name)
                .then(() => {
                    this.$_toasterSuccess('Identity successfully unlinked.')

                    const deletedIndex = this.userIdentities
                        .findIndex(identity => identity.provider_name === provider.provider_name)

                    this.userIdentities.splice(deletedIndex, 1)
                    this.setUserIdentities(this.userIdentities)
                })
                .catch(() => this.$_toasterDanger(`Failed to unlink social idenity.
                Please try again or contact support.`))
        },
    },
}
</script>
