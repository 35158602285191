var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function({handleSubmit, invalid}){return [_c('div',{staticClass:"row form-password"},[_c('div',{staticClass:"col-12 col-md-6"},[_c('div',{staticClass:"mb-3"},[_c('label',{staticClass:"card__info-label required"},[_vm._v("New Password")]),_c('ValidationProvider',{attrs:{"rules":"required","name":"password"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password),expression:"password"}],ref:"password",staticClass:"form-control",class:{ 'is-invalid' : errors.length },attrs:{"name":"password","type":"password"},domProps:{"value":(_vm.password)},on:{"input":function($event){if($event.target.composing)return;_vm.password=$event.target.value}}}),(errors.length)?_c('MessageValidationError',{attrs:{"msg":errors[0]}}):_vm._e()]}}],null,true)})],1),_c('div',{staticClass:"mb-3"},[_c('label',{staticClass:"card__info-label required"},[_vm._v("Confirm New Password")]),_c('ValidationProvider',{attrs:{"rules":"required|confirmed:password","name":"confirm new password"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password_confirmation),expression:"password_confirmation"}],staticClass:"form-control",class:{ 'is-invalid' : errors.length },attrs:{"name":"confirm password","type":"password"},domProps:{"value":(_vm.password_confirmation)},on:{"input":function($event){if($event.target.composing)return;_vm.password_confirmation=$event.target.value}}}),(errors.length)?_c('MessageValidationError',{attrs:{"msg":errors[0]}}):_vm._e()]}}],null,true)})],1)]),_c('div',{staticClass:"col-12 col-md-6"},[_c('h6',{staticClass:"mb-3"},[_vm._v(" Password must meet the following requirements: ")]),_c('div',{staticClass:"mb-2"},[_c('i',{staticClass:"mr-2",class:{
                        'fas fa-check-circle text-success' : _vm.hasUppercaseLetter,
                        'far fa-circle ': !_vm.hasUppercaseLetter,
                    },attrs:{"aria-hidden":"true"}}),_c('span',{class:{
                        'text-success': _vm.hasUppercaseLetter
                    }},[_vm._v(" At least one uppercase letter ")])]),_c('div',{staticClass:"mb-2"},[_c('i',{staticClass:"mr-2",class:{
                        'fas fa-check-circle text-success' : _vm.hasLowercaseLetter,
                        'far fa-circle ': !_vm.hasLowercaseLetter,
                    },attrs:{"aria-hidden":"true"}}),_c('span',{class:{
                        'text-success': _vm.hasLowercaseLetter
                    }},[_vm._v(" At least one lowercase letter ")])]),_c('div',{staticClass:"mb-2"},[_c('i',{staticClass:"mr-2",class:{
                        'fas fa-check-circle text-success' : _vm.hasNumber,
                        'far fa-circle ': !_vm.hasNumber,
                    },attrs:{"aria-hidden":"true"}}),_c('span',{class:{
                        'text-success': _vm.hasNumber
                    }},[_vm._v(" At least one number (0-9) ")])]),_c('div',{staticClass:"mb-2"},[_c('i',{staticClass:"mr-2",class:{
                        'fas fa-check-circle text-success' : _vm.hasMinCharacters,
                        'far fa-circle ': !_vm.hasMinCharacters,
                    },attrs:{"aria-hidden":"true"}}),_c('span',{class:{
                        'text-success': _vm.hasMinCharacters
                    }},[_vm._v(" At least "+_vm._s(_vm.passwordMinCharacters)+" characters ")])]),_c('div',{staticClass:"mb-2"},[_c('i',{staticClass:"mr-2",class:{
                        'fas fa-check-circle text-success' : _vm.doesNotContainSpaces,
                        'far fa-circle ': !_vm.doesNotContainSpaces,
                    },attrs:{"aria-hidden":"true"}}),_c('span',{class:{
                        'text-success': _vm.doesNotContainSpaces
                    }},[_vm._v(" Does not contain spaces ")])]),_c('div',{staticClass:"mb-2"},[_c('i',{staticClass:"mr-2",class:{
                        'fas fa-check-circle text-success' : _vm.confirmMatchesPassword,
                        'far fa-circle ': !_vm.confirmMatchesPassword,
                    },attrs:{"aria-hidden":"true"}}),_c('span',{class:{
                        'text-success': _vm.confirmMatchesPassword
                    }},[_vm._v(" Passwords must match ")])]),(_vm.redirectOnSubmit)?_c('InvisibleRecaptcha',{ref:"recaptcha",on:{"verified":_vm.verifiedRecaptcha,"challengeCancelled":_vm.resetLoading}}):_vm._e()],1),_c('div',{staticClass:"col-12 d-flex mt-3",class:{
                'justify-content-end' : !_vm.redirectOnSubmit,
            }},[_c('IconButton',{staticClass:"px-3",class:{
                    'btn-success' : !_vm.redirectOnSubmit,
                    'btn-primary py-2' : _vm.redirectOnSubmit
                },attrs:{"icon":_vm.redirectOnSubmit ? 'fa-arrow-right' : 'fa-key',"label":_vm.submitDisplayText,"aria-label":"Add Brand","loading":_vm.loading,"disabled":invalid || !_vm.submitEnabled},on:{"click":function($event){return handleSubmit(_vm.submit)}}})],1)])]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }