import accountsApi from '@api/accounts' //eslint-disable-line
import Vue from 'vue'

export default {
    namespaced: true,
    state: {
        loading: {
            featureToggles: false,
            featureToggle: false,
            updateFeatureToggleAccessLevel: false,
        },
        errors: {
            featureToggles: null,
            featureToggle: null,
            updateFeatureToggleAccessLevel: null,
        },
        featureToggles: [],
        featureToggle: {},
    },
    getters: {
        getLoadingState: state => name => state.loading[name],
        featureToggles: state => state.featureToggles,
        featureToggle: state => state.featureToggle,
        getErrorState: state => name => state.errors[name],
    },
    mutations: {
        setError(state, error) {
            Vue.set(state.errors, error.name, error.value)
        },
        setLoadingState(state, loading) {
            Vue.set(state.loading, loading.name, loading.value)
        },
        setFeatureToggles(state, features) {
            Vue.set(state, 'featureToggles', features)
        },
        setFeatureToggle(state, feature) {
            Vue.set(state, 'featureToggle', feature)
        },
    },
    actions: {
        loadFeatureToggles({ commit }) {
            commit('setLoadingState', { name: 'featureToggles', value: true })

            return accountsApi
                .getFeatureToggles()
                .then(response => {
                    const { data } = response
                    commit('setFeatureToggles', data.data)
                })
                .catch(() => {
                    commit('setError', { name: 'featureToggles', value: true })
                })
                .finally(() => {
                    commit('setLoadingState', { name: 'featureToggles', value: false })
                })
        },
        loadFeatureToggle({ commit }, uuid) {
            commit('setLoadingState', { name: 'featureToggles', value: true })

            return accountsApi
                .getFeatureToggle(uuid)
                .then(response => {
                    const { data } = response
                    commit('setFeatureToggle', data.data)
                })
                .catch(() => {
                    commit('setError', { name: 'featureToggle', value: true })
                })
                .finally(() => {
                    commit('setLoadingState', { name: 'featureToggle', value: false })
                })
        },
        updateFeatureToggleAccessLevel({ commit }, payload) {
            commit('setLoadingState', { name: 'updateFeatureToggleAccessLevel', value: true })

            return accountsApi.updateFeatureToggleAccessLevel(payload)
                .then(response => {
                    const { data } = response
                    commit('setFeatureToggle', data.data)
                })
                .catch(() => {
                    commit('setError', { name: 'updateFeatureToggleAccessLevel', value: true })
                })
                .finally(() => {
                    commit('setLoadingState', { name: 'updateFeatureToggleAccessLevel', value: false })
                })
        },
        initializeStore({ dispatch }) {
            dispatch('loadFeatureToggles')
        },
    },
}
