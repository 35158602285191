<template>
    <ClientBaseView
        title="Integration Hub"
        sub-title="Manage integrations with external products">
        <template #content>
            <component :is="currentComponent"></component>
        </template>
    </ClientBaseView>
</template>

<script>
import { ClientBaseView, Base404 } from '@technologyadvice/relay-frontend'
import ZoomInfoIntegration from './ZoomInfo/ZoomInfoIntegration.vue'

export default {
    components: {
        ClientBaseView,
        ZoomInfoIntegration,
        Base404,
    },
    data() {
        return {
            integrationComponents: {
                zoominfo: 'ZoomInfoIntegration',
            },
        }
    },
    computed: {
        currentComponent() {
            return this.integrationComponents[this.$route.params.type] || 'Base404'
        },
    },
}
</script>
