import axios from "@axios" //eslint-disable-line

export default {
    addBrand(data) {
        const postData = {
            ...data,
        }
        return axios.post('/brands', postData)
    },
    deleteBrand(uuid) {
        return axios.delete(`/brands/${uuid}`)
    },
    getBrands() {
        return axios.get('/brands')
    },
    getBrand(uuid) {
        return axios.get(`/brands/${uuid}`)
    },
    updateBrand(uuid, payload) {
        return axios.put(`/brands/${uuid}`, payload)
    },
}
