<template>
    <div class="brand-details">
        <BaseView
            title="Brand Details"
            sub-title="View and update brand information"
            :is-loading="isLoading"
            :load-error="loadError">
            <template #content>
                <div class="row">
                    <div class="col-lg-8 col-xl-9">
                        <BaseCard>
                            <template #title>
                                <i
                                    aria-hidden="true"
                                    class="fas fa-user mr-2 text-primary" />Brand Info
                            </template>

                            <template #header-link>
                                <router-link to="/brands">
                                    <i
                                        aria-hidden="true"
                                        class="fas fa-chevron-left mr-2" />Back to Brands
                                </router-link>
                            </template>

                            <template #body>
                                <ValidationObserver v-slot="{handleSubmit, invalid}">
                                    <div class="row mb-4">
                                        <div class="col-lg-9">
                                            <div class="card__info-label required mb-2">
                                                Name
                                            </div>

                                            <div class="mb-3">
                                                <ValidationProvider
                                                    v-slot="{ errors }"
                                                    rules="required"
                                                    name="brand name">
                                                    <input
                                                        v-model.trim="brand.name"
                                                        type="text"
                                                        :class="{ 'is-invalid' : errors.length }"
                                                        class="form-control"
                                                        name="name">

                                                    <MessageValidationError
                                                        v-if="errors.length"
                                                        :msg="errors[0]">
                                                    </MessageValidationError>
                                                </ValidationProvider>
                                            </div>
                                        </div>

                                        <div class="col-lg-9">
                                            <div class="card__info-label required">
                                                Deduplication Timeframe
                                            </div>

                                            <span
                                                aria-label="Brand deduplication timeframe value displayed in days">
                                                Timeframe displayed in days
                                                <i
                                                    v-tooltip="'Enter 0 for All Time'"
                                                    class="fas fa-info-circle"
                                                    aria-hidden="true" />
                                            </span>

                                            <div class="mt-2 mb-3">
                                                <ValidationProvider
                                                    v-slot="{ errors }"
                                                    rules="required|min_value:0"
                                                    name="deduplication timeframe">
                                                    <input
                                                        v-model.number="brand.deduplication_timeframe"
                                                        type="number"
                                                        min="0"
                                                        step="1"
                                                        :class="{ 'is-invalid' : errors.length }"
                                                        class="form-control mt-2"
                                                        name="deduplication_timeframe">

                                                    <MessageValidationError
                                                        v-if="errors.length"
                                                        :msg="errors[0]">
                                                    </MessageValidationError>
                                                </ValidationProvider>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="d-flex justify-content-end">
                                        <IconButton
                                            class="btn-success"
                                            icon="fa-save"
                                            label="Save"
                                            aria-label="Save"
                                            :loading="brandIsSaving"
                                            :disabled="!isDirty || invalid"
                                            @click="handleSubmit(save)"></IconButton>
                                    </div>
                                </ValidationObserver>
                            </template>
                        </BaseCard>
                    </div>
                </div>
            </template>
        </BaseView>
    </div>
</template>

<script>
import _ from 'lodash'
import {
    BaseView,
    BaseCard,
    MessageValidationError,
    IconButton,
} from '@technologyadvice/relay-frontend'
import { mapActions, mapGetters } from 'vuex'

export default {
    components: {
        BaseView,
        BaseCard,
        MessageValidationError,
        IconButton,
    },
    props: {
        uuid: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            emptyState: {
                name: '',
                deduplication_timeframe: 90,
            },
            initialBrandState: {},
        }
    },
    computed: {
        ...mapGetters('brands', [ 'brandList', 'getErrorState', 'getLoadingState', 'brand' ]),
        isLoading() {
            return this.getLoadingState('brand')
        },
        loadError() {
            return this.getErrorState('brand')
        },
        brandIsSaving() {
            return this.getLoadingState('brandSave')
        },
        brandSaveError() {
            return this.getErrorState('brandSave')
        },
        isDirty() {
            return !_.isEqual(
                { ...this.emptyState, ...this.brand },
                this.initialbrandState,
            )
        },
    },
    created() {
        this.loadBrand(this.uuid).then(() => {
            this.updateBrandInitialState()
        })

        if (this.brandList.length === 0) {
            this.initializeBrandManager()
        }
    },
    methods: {
        ...mapActions('brands', [
            'loadBrand',
            'updateBrandProperty',
            'saveBrand',
            'initializeBrandManager',
        ]),
        save() {
            this.saveBrand().then(() => {
                if (this.brandSaveError) {
                    this.handleErrorMessage(this.brandSaveError)
                } else {
                    this.$_toasterSuccess('Brand has been updated successfully')
                    this.updateBrandInitialState()
                }
            })
        },
        updateBrandInitialState() {
            this.initialBrandState = {
                ...this.emptyState,
                ...this.brand,
            }
        },
        handleErrorMessage(brandSaveError) {
            if (brandSaveError?.status === 422) {
                return this.$_toasterDanger(brandSaveError.data.message)
            }
            return this.$_toasterDanger('There was an error saving the brand.')
        },
    },
}
</script>
