<template>
    <ValidationObserver v-slot="{handleSubmit, invalid}">
        <BaseModal
            class="modal-add-attribute"
            modal-size="medium"
            @close="close">
            <template #header>
                <div class="d-flex align-items-center h5 mb-0">
                    <i
                        class="fas fa-plus-circle text-primary mr-2"
                        :aria-hidden="true" />
                    New Feature
                </div>
            </template>

            <template #body>
                <div class="row">
                    <ValidationProvider
                        v-slot="{errors}"
                        rules="required"
                        tag="div"
                        class="col-12 mb-3"
                        name="name">
                        <label class="card__info-label required">
                            Name
                        </label>
                        <input
                            v-model="attribute.name"
                            class="form-control"
                            :class="{'is-invalid': errors && errors.length}">
                        <MessageValidationError
                            v-if="errors && errors.length"
                            :msg="errors[0]">
                        </MessageValidationError>
                    </ValidationProvider>

                    <ValidationProvider
                        v-slot="{errors}"
                        rules="required"
                        tag="div"
                        class="col-12 mb-3"
                        name="type">
                        <div class="d-flex align-items-center mb-2">
                            <label class="card__info-label required m-0 p-0">
                                Type
                            </label>

                            <v-popover
                                trigger="hover"
                                placement="right-end"
                                popover-class="type-examples-popover">
                                <Icon
                                    icon="fa-info-circle"
                                    class="ml-2 text-primary">
                                </Icon>

                                <template
                                    slot="popover">
                                    <h6>Type Examples</h6>

                                    <div
                                        v-for="type in typesWithExamples"
                                        :key="type.name"
                                        class="popover-inner__type">
                                        <strong>{{ type.label }}</strong>

                                        <div class="popover-inner__type-example">
                                            {{ type.example }}
                                        </div>
                                    </div>
                                </template>
                            </v-popover>
                        </div>

                        <select
                            v-model="attribute.type"
                            class="form-control"
                            :class="{'is-invalid': errors && errors.length}">
                            <option
                                :value="null"
                                disabled
                                selected>
                                Select a type...
                            </option>

                            <option
                                v-for="type in types"
                                :key="type.name"
                                :value="type.name">
                                {{ type.label }}
                            </option>
                        </select>

                        <MessageValidationError
                            v-if="errors && errors.length"
                            :msg="errors[0]">
                        </MessageValidationError>
                    </ValidationProvider>

                    <div
                        class="col-12 mb-3">
                        <label class="card__info-label">
                            Description
                        </label>

                        <textarea
                            v-model="attribute.description"
                            class="form-control" />
                    </div>

                    <div
                        v-if="attribute.type && attribute.type !== 'solution_categories'"
                        class="col-12 mb-3">
                        <label class="card__info-label">
                            Default Value
                        </label>

                        <div v-if="attribute.type === 'boolean'">
                            <OptionBoxes
                                inline
                                type="radio"
                                :options="trueFalseOptions"
                                @change="selectTrueFalseOptions">
                            </OptionBoxes>
                        </div>

                        <div v-else>
                            <input
                                v-model="attribute.value"
                                class="form-control">
                        </div>
                    </div>
                </div>
            </template>

            <template #footer>
                <div class="container-fluid mx-0">
                    <div class="d-flex justify-content-between">
                        <button
                            class="btn btn-link mr-3 text-secondary"
                            @click="close">
                            <i
                                class="fas fa-times mr-1"
                                :aria-hidden="true" />
                            Cancel
                        </button>

                        <IconButton
                            class="btn-primary"
                            icon="fa-save"
                            label="Create"
                            :loading="isSubmitting"
                            :disabled="invalid"
                            @click="handleSubmit(create)"></IconButton>
                    </div>
                </div>
            </template>
        </BaseModal>
    </ValidationObserver>
</template>

<script>
import {
    BaseModal, Icon, OptionBoxes, IconButton, MessageValidationError,
} from '@technologyadvice/relay-frontend'
import api from '@api/accounts'

export default {
    components: {
        BaseModal,
        OptionBoxes,
        MessageValidationError,
        IconButton,
        Icon,
    },
    data() {
        return {
            attribute: {},
            isSubmitting: false,
            trueFalseOptions: [
                {
                    label: 'True',
                    property: 'true',
                    enabled: false,
                },
                {
                    label: 'False',
                    property: 'false',
                    enabled: false,
                },
            ],
        }
    },
    computed: {
        types() {
            return this.$readableTypes.all()
        },
        typesWithExamples() {
            return this.types.filter(type => type.example)
        },
    },
    methods: {
        selectTrueFalseOptions(value) {
            this.trueFalseOptions = this.trueFalseOptions.map(option => ({
                label: option.label,
                property: option.property,
                enabled: value[option.property],
            }))

            const { property } = this.trueFalseOptions.find(({ enabled }) => enabled)

            this.attribute.value = property
        },
        close() {
            this.$emit('close')
        },
        create() {
            // Slugify the name into a code
            this.attribute.code = this.$options.filters.slugify(this.attribute.name)
            this.isSubmitting = true
            return api.storeAttribute(this.attribute)
                .then(() => {
                    this.$_toasterSuccess('Successfully created a new feature')

                    this.$emit('reload')
                    this.close()
                })
                .catch(() => {
                    this.$_toasterDanger('Failed to create a new feature')
                })
                .finally(() => {
                    this.isSubmitting = false
                })
        },
    },
}
</script>
