<template>
    <div
        v-cloak
        id="login"
        class="container-fluid h-100 login__bkg">
        <div class="d-flex justify-content-center align-items-center h-100">
            <div class="login">
                <div class="mb-4">
                    <img
                        alt="Traction Logo"
                        :src="logo"
                        class="login__logo">
                </div>

                <div
                    v-if="ssoError"
                    class="login__sso-error-container d-flex alert alert-danger text-light my-3">
                    <Icon
                        icon="fa-info-circle"
                        size="lg"
                        class="mr-2"></Icon>
                    <span>
                        We weren't able to authenticate your account.
                        Please try signing in with your username/email and password instead.
                    </span>
                </div>

                <form class="login__container">
                    <div>
                        <a
                            :href="`/accounts/bff/redirect?${redirectQueryParam}`"
                            class="google-login__btn d-flex align-items-center btn text-secondary px-3">
                            <img
                                alt="Google Logo"
                                :src="googleLogo"
                                class="google-login__logo">
                            <span class="mx-auto">
                                Sign In With Google
                            </span>
                        </a>
                    </div>

                    <div class="separator text-uppercase mt-3 mb-2">
                        or, use email
                    </div>

                    <div class="form-group">
                        <label class="card__info-label mb-2">Username/Email</label>
                        <input
                            id="login__email"
                            v-model="loginData.email"
                            name="email"
                            class="login__input form-control"
                            placeholder="Username/Email"
                            type="text">
                    </div>
                    <div class="form-group">
                        <label class="card__info-label mb-2">Password</label>
                        <input
                            id="login__password"
                            v-model="loginData.password"
                            :class="[ passwordError ? 'is-invalid' : '' ]"
                            name="password"
                            class="login__input form-control"
                            placeholder="Password"
                            type="password">
                        <message-validation-error
                            v-if="passwordError"
                            :msg="passwordError">
                        </message-validation-error>
                    </div>

                    <InvisibleRecaptcha
                        ref="recaptcha"
                        @verified="attemptLogin"
                        @challengeCancelled="resetIsSubmitting"></InvisibleRecaptcha>

                    <div class="mt-4">
                        <button
                            :disabled="!loginData.email || !loginData.password || isSubmitting"
                            class="btn btn-primary btn-block login__submit-btn"
                            type="submit"
                            @click.prevent="submit">
                            <span v-if="isSubmitting">
                                <i
                                    aria-hidden="true"
                                    class="fas fa-spinner fa-spin" /> Signing in...
                            </span>
                            <span v-else>
                                Sign In with Username/Email
                            </span>
                        </button>
                    </div>

                    <div class="mt-3 text-center d-flex align-items-center justify-content-center">
                        <router-link
                            class="btn btn-link text-secondary"
                            :to="{ name: 'ForgotPassword'}">
                            Forgot password?
                        </router-link>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
/* eslint-disable indent */
import { Icon, MessageValidationError } from '@technologyadvice/relay-frontend'
import { mapActions, mapGetters } from 'vuex'
import InvisibleRecaptcha from '@components/ui/InvisibleRecaptcha.vue'
import Swal from 'sweetalert2'
import logo from '@technologyadvice/relay-frontend/src/assets/brand-logo.svg'
import sidebar from '@technologyadvice/relay-frontend/src/util/sidebar'
import googleLogo from '../assets/imgs/logo/google-logo.svg'

export default {
    name: 'Login',
    components: {
        Icon,
        MessageValidationError,
        InvisibleRecaptcha,
    },
    data() {
        const params = (new URL(document.location)).searchParams
        let redirectQueryParam = ''

        if (params.get('redirect')) {
            redirectQueryParam = `redirect=${params.get('redirect')}`
            redirectQueryParam += params.get('redirect_encoded')
                ? `&redirect_encoded=${params.get('redirect_encoded')}`
                : ''
        }

        return {
            logo,
            googleLogo,
            passwordError: '',
            ssoError: false,
            loginData: {
                email: '',
                password: '',
                recaptcha: '',
            },
            isSubmitting: false,
            redirectQueryParam,
        };
    },
    computed: {
        ...mapGetters('auth', [
            'user',
        ]),
        formattedLoginCredentials() {
            if (!this.validEmail(this.loginData.email) && this.loginData.email.indexOf('@') === -1) {
                return {
                    ...this.loginData,
                    email: `${this.loginData.email}@callcenter.com`,
                    redirect: this.redirectQueryParam,
                }
            }

            return { ...this.loginData, redirect: this.redirectQueryParam }
        },
    },
    beforeMount() {
        const errorCode = this.$route.query.error
        if (errorCode) {
            this.setError(Number(errorCode))
        }
        const { inactivity } = this.$route.query
        if (inactivity) {
            Swal.fire({
                title: 'Your session has expired',
                text: 'Please login again',
                type: 'warning',
                timer: 180 * 1000,
                customClass: {
                    container: 'inactivity',
                },
            })
        }
    },
    mounted() {
        window.onbeforeunload = () => {
            // oauth flow authentication
            sidebar.forget()
        }
    },
    beforeDestroy() {
        // user/pass authentication
        window.onbeforeunload = null
        sidebar.forget()
    },
    methods: {
        ...mapActions('auth', [
            'login',
        ]),
        attemptLogin(recaptchaToken) {
            this.loginData.recaptcha = recaptchaToken
            this.login(this.formattedLoginCredentials)
                .then(({ redirect }) => {
                    // if a redirect was passed from the back end, we should use it
                    if (redirect) {
                        window.location = redirect
                    } else {
                        // A redirect path was specified
                        if (this.$route.query.redirect) {
                            if (this.$route.query.external) {
                                window.location = this.$route.query.redirect
                            } else {
                                this.$router.push({
                                    path: this.$route.query.redirect,
                                })
                            }
                        }

                        // A redirect path was not specified. Calculate one.
                        const redirectUrl = this.getRedirectUrl()
                        if (redirectUrl) {
                            window.location = redirectUrl
                        }
                        this.$router.push({
                            path: '/',
                        })
                    }
                })
                .catch(error => {
                    this.setError(error.response.data.status)
                })
                .finally(() => {
                    this.isSubmitting = false
                })
        },
        canOnlyAccessSinglePortal(portal) {
            return this.user.roles.filter(i => i.code.toLowerCase().includes(portal)).length === this.user.roles.length
        },
        getRedirectUrl() {
            if (this.canOnlyAccessSinglePortal('qa')) {
                if (this.hasRole('qa_manager')) {
                    return '/qa/priority-queue'
                }
                return '/qa'
            }
            if (this.canOnlyAccessSinglePortal('csm')) {
                return '/csm/programs'
            }
            if (this.canOnlyAccessSinglePortal('delivery')) {
                return '/delivery/delivery-manager/needs-setup'
            }
            if (this.canOnlyAccessSinglePortal('fulfillment')) {
                return '/fulfillment/program-queue/needs-attention'
            }
            if (this.canOnlyAccessSinglePortal('out-task')) {
                return '/out-task/program-queue/needs-attention'
            }
            if (this.canOnlyAccessSinglePortal('partner')) {
                return '/out-task/program-partners'
            }
            if (this.canOnlyAccessSinglePortal('client')) {
                return '/client/dashboard'
            }

            // We should show the landing page
            return null
        },
        hasRole(role) {
            return this.user.roles.filter(i => i.code.toLowerCase().includes(role)).length > 0
        },
        resetIsSubmitting() {
            this.isSubmitting = false
        },
        setError(errorCode) {
            let error = ''
            switch (errorCode) {
            case 500:
                error = 'A system error has occurred'
                break
            case 422:
                error = 'Recaptcha failed'
                break
            case 401:
                this.ssoError = true
                break
            default:
                error = 'Invalid username or password'
            }
            this.passwordError = error
        },
        submit() {
            this.isSubmitting = true;
            // Execute recaptcha
            this.$refs.recaptcha.execute()
        },
        validEmail(email) {
            // eslint-disable-next-line max-len
            const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return re.test(email)
        },
    },
};
</script>
