<template>
    <BaseModal
        modal-size="medium"
        :scroll-content="false">
        <template #header>
            <h3 class="h4">
                Disable Integration
            </h3>
        </template>
        <template #body>
            <slot name="modal-body" />
        </template>
        <template #footer>
            <div class="d-flex">
                <button
                    type="button"
                    class="btn btn-link"
                    aria-label="Cancel"
                    @click="close">
                    Cancel
                </button>
                <button
                    type="button"
                    class="btn btn-danger ml-3"
                    aria-label="Disable"
                    :disabled="isLoading"
                    @click="handleDisable">
                    Disable <span v-if="isLoading"><LoadingSpinner
                        class="p-0 d-inline"
                        style="font-size: 1rem"></LoadingSpinner></span>
                </button>
            </div>
        </template>
    </BaseModal>
</template>
<script>
import { BaseModal, LoadingSpinner } from '@technologyadvice/relay-frontend';

export default {
    components: {
        BaseModal,
        LoadingSpinner,
    },
    data() {
        return {
            isLoading: false,
        }
    },
    methods: {
        close() {
            this.$emit('close')
        },
        handleDisable() {
            this.isLoading = !this.isLoading
            this.$emit('disable-integration', true)
        },
    },
}
</script>
