<template>
    <ValidationObserver v-slot="{handleSubmit, invalid}">
        <BaseModal
            class="modal-add-plan"
            modal-size="medium"
            @close="close">
            <template #header>
                <div class="d-flex align-items-center h5 mb-0">
                    <i
                        class="fas fa-plus-circle text-primary mr-2"
                        :aria-hidden="true" />
                    New Plan
                </div>
            </template>

            <template #body>
                <div class="row">
                    <ValidationProvider
                        v-slot="{errors}"
                        rules="required"
                        tag="div"
                        class="col-12 mb-3"
                        name="name">
                        <label class="card__info-label required">
                            Name
                        </label>
                        <input
                            v-model="plan.name"
                            class="form-control"
                            :class="{'is-invalid': errors && errors.length}">
                        <MessageValidationError
                            v-if="errors && errors.length"
                            :msg="errors[0]">
                        </MessageValidationError>
                    </ValidationProvider>

                    <div
                        class="col-12 mb-3">
                        <label class="card__info-label">
                            Description
                        </label>

                        <textarea
                            v-model="plan.description"
                            class="form-control" />
                    </div>
                </div>
            </template>

            <template #footer>
                <div class="container-fluid mx-0">
                    <div class="d-flex justify-content-between">
                        <button
                            class="btn btn-link text-secondary"
                            @click="close">
                            <i
                                class="fas fa-times mr-1"
                                :aria-hidden="true" />
                            Cancel
                        </button>

                        <IconButton
                            class="btn-primary"
                            icon="fa-save"
                            label="Create"
                            :loading="isSubmitting"
                            :disabled="invalid"
                            @click="handleSubmit(create)"></IconButton>
                    </div>
                </div>
            </template>
        </BaseModal>
    </ValidationObserver>
</template>

<script>
import {
    BaseModal, IconButton,
    MessageValidationError,
} from '@technologyadvice/relay-frontend'
import api from '@api/accounts'

export default {
    components: {
        BaseModal,
        MessageValidationError,
        IconButton,
    },
    data() {
        return {
            plan: {},
            isSubmitting: false,
        }
    },
    methods: {
        close() {
            this.$emit('close')
        },
        create() {
            // Slugify the name into a code
            this.plan.code = this.$options.filters.slugify(this.plan.name)
            this.isSubmitting = true
            return api.storePlan(this.plan)
                .then(() => {
                    this.$_toasterSuccess('Successfully created a new plan')

                    this.$emit('reload')
                    this.close()
                })
                .catch(() => {
                    this.$_toasterDanger('Failed to create a new plan')
                })
                .finally(() => {
                    this.isSubmitting = false
                })
        },
    },
}
</script>
