<template>
    <div>
        <div
            v-if="shouldDisplayPromotionCard"
            class="p-4">
            <div>
                <h4 class="pb-4">
                    Welcome to Traction!
                </h4>
            </div>
            <PromotionCard
                :is-home-page="true"
                @close="handlePromotionClose">
            </PromotionCard>
        </div>

        <div v-else>
            <div class="d-flex justify-content-center">
                <img
                    :alt="isDomainGDP ? 'Global Demand Home' : 'Traction Home'"
                    class="welcome-image my-4"
                    :src="isDomainGDP ? refreshHomeImageGDP : refreshHomeImage">
            </div>
            <div class="text-center">
                <h1>Welcome to {{ isDomainGDP ? 'Global Demand' : 'Traction' }}!</h1>
                <div>
                    To get started, select a portal from the navigation list.
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import refreshHomeImage from '@technologyadvice/relay-frontend/src/assets/branding/design-refresh-home.png'
import refreshHomeImageGDP from '@technologyadvice/relay-frontend/src/assets/branding/gdp-home.png'
import PromotionCard from '@/components/promo/PromotionCard.vue';

export default {
    components: {
        PromotionCard,
    },
    props: {
        isDomainGDP: {
            type: Boolean,
            required: true,
        },
    },
    data() {
        return {
            refreshHomeImage,
            refreshHomeImageGDP,
            shouldShowPromotionCard: false,
        }
    },
    computed: {
        ...mapGetters('auth', [
            'hasScope',
            'hasFeature',
        ]),
        shouldDisplayPromotionCard() {
            return (
                this.shouldShowPromotionCard
                && this.hasScope('external-client')
                && this.hasFeature('zoom-info-traction-promo')
            )
        },
    },
    created() {
        const dismissed = localStorage.getItem('promotionDismissed')
        this.shouldShowPromotionCard = !dismissed
    },
    methods: {
        handlePromotionClose() {
            this.shouldShowPromotionCard = false
            localStorage.setItem('promotionDismissed', 'true')
        },
    },
};
</script>
