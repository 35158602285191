<template>
    <div class="home">
        <h1>
            <i
                aria-hidden="true"
                class="fas fa-home" />
        </h1>
        Help
    </div>
</template>

<script>
export default {
    name: 'Help',
};
</script>
