<template>
    <button
        class="btn integration-status-indicator"
        :class="connected ? 'integration-status-indicator__connected' : 'integration-status-indicator__disabled'">
        <span class="font-weight-bold">
            {{ connected ? 'Connected' : 'Configure to Activate' }}
        </span>
    </button>
</template>

<script>
export default {
    props: {
        connected: {
            type: Boolean,
            required: true,
        },
    },
}
</script>
