<template>
    <!-- Request made, awaiting response from backend -->
    <div v-if="status === 'validating'">
        <h3 class="h5">
            <i
                :aria-hidden="true"
                class="fas fa-circle-notch fa-spin" />
            Validating Email
        </h3>

        We are working to validate your email, if this process takes more than 1 minute
        feel free to refresh or reach out to us at <SupportEmail></SupportEmail>.
    </div>

    <!-- This email has already been validated, still awaiting approval -->
    <div v-else-if="status === 'already-validated-awaiting-approval'">
        <h3 class="h5">
            <i
                :aria-hidden="true"
                class="fas fa-exclamation-triangle text-warning" />
            Already Validated - Awaiting Approval
        </h3>

        <div>
            Your email has already been validated. You will receive an email when
            your account has been approved by our internal administrators. If we
            have not approved your account within a few days of registration feel
            free to reach out to our support team at <SupportEmail></SupportEmail>.
        </div>

        <br>

        <div>
            Thank you for your patience.
        </div>
    </div>

    <!-- This email has already been validated, approved - they can login -->
    <div v-else-if="status === 'already-validated'">
        <h3 class="h5">
            <i
                :aria-hidden="true"
                class="fas fa-check-circle text-success" />
            Already Validated
        </h3>

        Your email has already been validated, feel free to
        <router-link :to="{name: 'login'}">
            login to your account
        </router-link>.

        If you are still having trouble logging in don't hesitate to reach our to
        our support team at <SupportEmail></SupportEmail>.
    </div>

    <!-- Success, but the user requires CSM verification/approval -->
    <div v-else-if="status === 'success-awaiting-approval'">
        <h3 class="h5">
            <i
                :aria-hidden="true"
                class="fas fa-check-circle text-success" />
            Success - Awaiting Approval
        </h3>

        Your email has been validated. You will receive an email when your account has been approved
        by our internal administrators. If we have not approved your account within a few days of
        registration feel free to reach out to our support team at <SupportEmail></SupportEmail>.
    </div>

    <!-- Success, the user may log in and start using the application -->
    <div v-else-if="status === 'success'">
        <h3 class="h5">
            <i
                :aria-hidden="true"
                class="fas fa-check-circle text-success" />
            Success
        </h3>

        Your email has been validated, redirecting you to create a password in {{ redirectCountdown }} seconds.
    </div>

    <!-- Fail state -->
    <div v-else>
        <h3 class="h5">
            <i
                :aria-hidden="true"
                class="fas fa-times-circle text-danger" />
            Failed
        </h3>

        Failed to validate your email, feel free to retry or reach out to our support team at
        <SupportEmail></SupportEmail>.
    </div>
</template>

<script>
import api from '@api/auth'
import SupportEmail from '@components/common/SupportEmail.vue'

export default {
    components: {
        SupportEmail,
    },
    props: {
        token: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            // Available statuses are:
            // validating/already-validated-awaiting-approval/already-validated
            // success-awaiting-approval/success/failed
            status: 'validating',
            redirectCountdown: 0,
        }
    },
    created() {
        this.validateToken()
    },
    methods: {
        validateToken() {
            return api.verifyEmail(this.token)
                .then(({ data }) => {
                    this.status = data.data.status

                    if (this.status === 'success') {
                        this.redirectCountdown = 3
                        setInterval(() => {
                            this.redirectCountdown--

                            // Countdown hit zero, redirect us
                            if (this.redirectCountdown === 0) {
                                this.$router.push({
                                    name: 'ResetPassword',
                                    query: { email: data.data.email },
                                    params: { token: data.data.token },
                                })
                            }
                        }, 1000)
                    }
                })
                .catch(() => {
                    this.status = 'failed'
                })
        },
    },
}
</script>
