<template>
    <div>
        <div v-if="isLoading">
            <LoadingSpinner></LoadingSpinner>
        </div>
        <div
            v-else
            class="col-12 col-md-6 px-0">
            <h5>API Key</h5>
            <div class="mb-4">
                <div class="mb-1">
                    Paste API Key generated from ZoomInfo Admin Panel
                </div>

                <input
                    v-model.trim="apiKey"
                    type="text"
                    :class="{ 'is-invalid' : tokenError }"
                    class="form-control"
                    name="apikey"
                    placeholder="XXXXXXXXXXXXX"
                    :disabled="!canModifySettings">

                <MessageValidationError
                    v-if="tokenError"
                    msg="Please enter a valid API key">
                </MessageValidationError>
            </div>

            <h5>Integration Status</h5>
            <div class="mb-4">
                <div class="mb-1">
                    Set the status to enabled for data to flow
                </div>

                <input
                    id="integrationEnabled"
                    v-model="enabled"
                    type="radio"
                    value="enabled"
                    class="mr-1"
                    :disabled="!canModifySettings">
                <label for="integrationEnabled">Enabled</label>

                <input
                    id="integrationDisabled"
                    v-model="enabled"
                    type="radio"
                    value="disabled"
                    class="mr-1 ml-3"
                    :disabled="!canModifySettings">
                <label for="integrationDisabled">Disabled</label>
            </div>

            <Button
                id="saveSettings"
                class="btn btn-primary mr-4"
                :disabled="submitting || !canModifySettings"
                @click="handleSave()">
                <i
                    v-if="submitting"
                    class="fas fa-spinner fa-spin mx-auto"
                    aria-hidden="true" />
                Save Settings
            </Button>

            <Button
                v-scope:sync-integration
                class="btn btn-link text-blue-300 align-items-center"
                :disabled="dispatching"
                @click="syncData">
                <i
                    v-if="dispatching"
                    class="fas fa-spinner fa-spin"
                    aria-hidden="true" />
                <i
                    v-else
                    class="fas fa-sync-alt"
                    aria-hidden="true" />
                <span class="ml-2">Sync Data</span>
            </Button>
        </div>
    </div>
</template>

<script>
import { LoadingSpinner, MessageValidationError } from '@technologyadvice/relay-frontend'
import { mapState } from 'pinia'
import { useIntegrationsStore } from '@store/integrations'
import { mapGetters } from 'vuex'
import api from '@api/integrations'

export default {
    components: {
        LoadingSpinner,
        MessageValidationError,
    },
    data() {
        return {
            apiKey: null,
            enabled: 'disabled',
            submitting: false,
            tokenError: false,
            dispatching: false,
        }
    },
    watch: {
        isLoading: {
            handler() {
                this.loadForm()
            },
            deep: true,
        },
    },
    computed: {
        ...mapGetters('auth', [
            'user',
        ]),
        ...mapState(useIntegrationsStore, [
            'integrations',
            'loading',
        ]),
        isLoading() {
            return this.loading
        },
        integration() {
            return this.integrations.find(integration => integration.type === 'zoom_info')
        },
        canModifySettings() {
            return this.user.is_owner || this.user.is_internal
        },
    },
    created() {
        this.loadForm()
    },
    methods: {
        loadForm() {
            this.apiKey = this.integration?.credentials?.token
            this.enabled = this.integration?.enabled ? 'enabled' : 'disabled'
        },
        handleSave() {
            this.submitting = true
            this.tokenError = false
            const store = useIntegrationsStore()
            const payload = { enabled: this.enabled === 'enabled' }

            if (this.apiKey) {
                // if future integrations are added,
                // we likely will want to enforce some sort of credential type structure for different integrations
                payload.credentials = { token: this.apiKey }
            }

            if (this.integration) {
                this.updateSettings(store, payload)
            } else {
                this.createIntegration(store, payload)
            }
        },
        createIntegration(store, settings) {
            api.storeIntegration({
                type: 'zoom_info',
                ...settings,
            }).then(({ data }) => {
                this.$_toasterSuccess('Integration settings saved!')
                store.$patch(state => { state.integrations.push(data.data) })
            }).catch(err => {
                if (err?.response?.status === 422) {
                    this.tokenError = true
                }

                this.$_toasterDanger('Integration settings could not be saved. Please try again or contact support.')
            }).finally(() => { this.submitting = false })
        },
        updateSettings(store, settings) {
            api.updateIntegrations(this.integration.id, settings)
                .then(({ data }) => {
                    this.$_toasterSuccess('Integration settings saved!')

                    store.$patch(state => {
                        const index = state.integrations.findIndex(integration => integration.id === data.data.id)
                        state.integrations[index].credentials = data.data.credentials
                        state.integrations[index].enabled = data.data.enabled
                    })
                })
                .catch(err => {
                    if (err?.response?.status === 422) {
                        this.tokenError = true
                    }

                    this.$_toasterDanger(
                        'Integration settings could not be updated. Please try again or contact support.',
                    )
                })
                .finally(() => { this.submitting = false })
        },
        syncData() {
            if (this.integration && this.integration.type === 'zoom_info') {
                this.dispatching = true
                api.syncData({ account_uuid: this.integration.account_uuid })
                    .then(({ data }) => {
                        this.$_toasterSuccess(data.message)
                    })
                    .catch(() => {
                        this.$_toasterDanger('Failed to dispatch sync request')
                    })
                    .finally(() => {
                        this.dispatching = false
                    })
            } else {
                this.$_toasterDanger('Invalid integration type')
            }
        },
    },
}
</script>
