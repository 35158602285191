<template>
    <div>
        <BaseView
            title="Brand Management"
            sub-title="Manage the brands for your clients"
            :is-loading="isLoading"
            :load-error="loadError">
            <template #header-link>
                <button
                    class="btn btn-primary"
                    @click="openModalAddBrand">
                    <i
                        aria-hidden="true"
                        class="fas fa-plus" /> Add Brand
                </button>
            </template>
            <template #content>
                <BaseCard>
                    <template #title>
                        <i
                            aria-hidden="true"
                            class="fas fa-handshake mr-1 text-primary" />Brands
                    </template>

                    <template #body>
                        <ModalAddBrand
                            v-if="showAddBrandModal"
                            @close="closeModalAddBrand"
                            @reload="initializeBrandManager"></ModalAddBrand>
                        <TableBrands></TableBrands>
                    </template>
                </BaseCard>
            </template>
        </BaseView>
    </div>
</template>

<script>
import { BaseView, BaseCard } from '@technologyadvice/relay-frontend';
import { mapActions, mapGetters } from 'vuex';
import ModalAddBrand from '@components/brands/ModalAddBrand.vue';
import TableBrands from '@components/brands/TableBrands.vue';

export default {
    name: 'Brands',
    components: {
        BaseView,
        BaseCard,
        ModalAddBrand,
        TableBrands,
    },
    data() {
        return {
            showAddBrandModal: false,
        };
    },
    computed: {
        ...mapGetters('brands', [ 'getLoadingState', 'getErrorState', 'brandList' ]),
        isLoading() {
            return this.getLoadingState('brandList');
        },
        loadError() {
            return this.getErrorState('brandList');
        },
    },
    created() {
        this.initializeBrandManager();
    },
    methods: {
        ...mapActions('brands', [ 'initializeBrandManager' ]),
        openModalAddBrand() {
            this.showAddBrandModal = true;
        },
        closeModalAddBrand() {
            this.showAddBrandModal = false;
        },
    },
};
</script>
