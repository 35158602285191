<template>
    <div
        v-cloak
        class="container-fluid h-100 loginV2__bkg">
        <div class="d-flex justify-content-center align-items-center h-100">
            <div class="reset-password__container">
                <div class="mb-4">
                    <img
                        alt="Traction Logo"
                        :src="isDomainGDP ? logoGDP : logo"
                        class="loginV2__logo reset-password--logo-main">
                </div>
                <Transition
                    mode="out-in"
                    name="fade">
                    <BaseCard
                        v-if="tokenInvalid !== null"
                        class="card-reset-password"
                        :show-header="false">
                        <template #body>
                            <div class="card-reset-password__content-container">
                                <div class="card-reset-password__sidebar" />
                                <div class="card-reset-password__body">
                                    <Transition
                                        mode="out-in"
                                        name="fade">
                                        <div
                                            v-if="resent"
                                            key="0">
                                            <h4>Invite Resent</h4>
                                            <p class="mb-4">
                                                We sent you another link. Please use the new link to accept
                                                your invite and activate your account.
                                            </p>
                                            <p v-if="params.accountUuid">
                                                If you have received invitation emails to join additional accounts,
                                                you will have to complete account registration first. Once completed,
                                                you may try joining those accounts again.
                                            </p>
                                            <p>
                                                If you're having trouble finding the email with your new link
                                                please remember to check your spam folder.
                                            </p>
                                        </div>
                                        <div
                                            v-else-if="tokenInvalid"
                                            :key="1">
                                            <h4>Whoops 😞</h4>
                                            <p class="mb-4">
                                                We're having trouble finding your invite, your link may have expired.
                                            </p>
                                            <p v-if="params.email">
                                                <!-- send another invite -->
                                                If you haven't already accepted your invite we can <a
                                                    href="#"
                                                    @click.prevent="resend">send you another
                                                    link</a>.
                                            </p>
                                            <p>
                                                <!-- try to login / forgot password -->
                                                If you've already accepted your invite you can <router-link
                                                    :to="{ name: 'loginV2' }">
                                                    click here to sign in
                                                </router-link>.
                                            </p>
                                        </div>

                                        <div
                                            v-else
                                            :key="2">
                                            <div>
                                                <h4>Welcome!</h4>
                                                <p class="mb-4">
                                                    To finish setting up your account, please create a secure password
                                                    or login with Google.
                                                </p>
                                            </div>

                                            <div>
                                                <a
                                                    href="/accounts/bff/redirect"
                                                    class="google-login__btn d-flex mx-auto
                                                    align-items-center btn text-secondary px-3">
                                                    <span class="mx-auto">
                                                        <img
                                                            alt="Google Logo"
                                                            :src="googleLogo"
                                                            class="google-login__logo">
                                                        <span class="ml-1">
                                                            Sign In With Google
                                                        </span>
                                                    </span>
                                                </a>
                                            </div>

                                            <div class="separator text-uppercase my-3">
                                                or
                                            </div>

                                            <FormPassword
                                                :callback="submit"
                                                submit-text="Set Password & Continue">
                                            </FormPassword>
                                        </div>
                                    </Transition>
                                </div>
                            </div>
                        </template>
                    </BaseCard>
                    <div v-else>
                        <LoadingSpinner></LoadingSpinner>
                    </div>
                </Transition>
            </div>
        </div>
    </div>
</template>

<script>
import { BaseCard, LoadingSpinner } from '@technologyadvice/relay-frontend'
import api from '@api/accounts'
import logo from '@technologyadvice/relay-frontend/src/assets/brand-logo-refresh-white-text.svg'
import logoGDP from '@technologyadvice/relay-frontend/src/assets/gdp-logo.png'
import FormPassword from '@components/common/FormPassword.vue'
import googleLogo from '../assets/imgs/logo/google-logo.svg'

export default {
    name: 'UserInvite',
    components: {
        BaseCard,
        FormPassword,
        LoadingSpinner,
    },
    props: {
        token: {
            type: String,
            required: true,
        },
    },
    data() {
        const query = new URLSearchParams(window.location.search)

        return {
            params: {
                email: query.get('email'),
                token: this.token,
                accountUuid: query.get('account_uuid') || null,
            },
            resent: false,
            tokenInvalid: null,
            logo,
            logoGDP,
            googleLogo,
            isDomainGDP: false,
        }
    },
    created() {
        if (window.location.hostname.includes('globaldemandpartners.com')) {
            this.isDomainGDP = true
            document.documentElement.setAttribute('data-theme', 'gdp')
        }
    },
    mounted() {
        this.checkToken()
    },
    methods: {
        checkToken() {
            return api.checkInvite(this.params)
                .then(() => {
                    this.tokenInvalid = false;
                })
                .catch(err => {
                    this.tokenInvalid = true;

                    const userAlreadyRegistered = err.response.status === 422
                        && err.response.data.data.message === "You've already accepted your invite."

                    // handles edge case around account registration and invitations to join additional accounts
                    if (userAlreadyRegistered && this.params.accountUuid) {
                        this.$router.push({ path: `/login?redirect=/join-account/${this.params.accountUuid}` })
                    }
                })
        },
        submit(passwords) {
            return api.acceptInvite({ ...passwords, ...this.params })
                .then(({ data }) => {
                    const user = data.data

                    this.$store.commit('auth/setUser', user)

                    if (this.params.accountUuid) {
                        this.$router.replace({
                            name: 'JoinAccount',
                            params: { account_uuid: this.params.accountUuid },
                        })
                    } else {
                        this.$router.replace({
                            name: 'Profile',
                            params: { user_uuid: user.uuid },
                        })
                    }
                })
                .catch(() => {
                    this.$_toasterDanger(
                        'There was a problem activating your account. '
                            + 'Please try again or contact support.',
                    )
                })
        },
        resend() {
            return api.sendInvite(this.params)
                .then(() => {
                    this.resent = true
                })
                .catch(() => {
                    this.$_toasterDanger(
                        'There was a problem resending your invite. '
                            + 'Please try again in 5 minutes or contact support.',
                    )
                })
        },
    },
}
</script>
