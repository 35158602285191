<template>
    <a :href="`mailto:${email}`">{{ email }}</a>
</template>

<script>

export default {
    data() {
        return {
            email: 'support@technologyadvice.com',
        }
    },
}
</script>
