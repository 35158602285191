<template>
    <BaseModal
        class="modal-add-brand"
        modal-size="medium"
        :scroll-content="false"
        :show-header="false"
        :show-footer="false"
        @close="close">
        <template #body>
            <ValidationObserver v-slot="{handleSubmit, invalid}">
                <div class="modal-add-brand__body">
                    <h5>Create a Brand</h5>

                    <div class="mb-4">
                        <div class="card__info-label required mb-1">
                            Name
                        </div>

                        <ValidationProvider
                            v-slot="{ errors }"
                            rules="required|unique-brand"
                            name="brand name">
                            <input
                                v-model.trim="brand.name"
                                type="text"
                                :class="{ 'is-invalid' : errors.length }"
                                class="form-control"
                                name="name">

                            <MessageValidationError
                                v-if="errors.length"
                                :msg="errors[0]">
                            </MessageValidationError>
                        </ValidationProvider>
                    </div>

                    <div class="mb-4">
                        <div class="card__info-label required">
                            Deduplication Timeframe
                        </div>

                        <span
                            aria-label="Please enter the number of days for brand deduplication timeframe">
                            Please input a number in days
                            <i
                                v-tooltip="'Enter 0 for All Time'"
                                class="fas fa-info-circle"
                                aria-hidden="true" />
                        </span>

                        <ValidationProvider
                            v-slot="{ errors }"
                            rules="required|min_value:0"
                            name="deduplication timeframe">
                            <input
                                v-model.number="brand.deduplication_timeframe"
                                type="number"
                                min="0"
                                step="1"
                                :class="{ 'is-invalid' : errors.length }"
                                class="form-control mt-2"
                                name="deduplication_timeframe">

                            <MessageValidationError
                                v-if="errors.length"
                                :msg="errors[0]">
                            </MessageValidationError>
                        </ValidationProvider>
                    </div>

                    <div class="d-flex justify-content-between">
                        <button
                            type="button"
                            class="btn btn-link"
                            aria-label="Cancel"
                            @click="close">
                            <i
                                aria-hidden="true"
                                class="fas fa-times-circle mr-1" /> Cancel
                        </button>
                        <IconButton
                            class="btn-success"
                            icon="fa-save"
                            label="Add Brand"
                            aria-label="Add Brand"
                            :loading="isSubmitting"
                            :disabled="invalid"
                            @click="handleSubmit(submit)"></IconButton>
                    </div>
                </div>
            </ValidationObserver>
        </template>
    </BaseModal>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { extend } from 'vee-validate'
import {
    IconButton,
    BaseModal,
    MessageValidationError,
} from '@technologyadvice/relay-frontend'

export default {
    components: {
        BaseModal,
        MessageValidationError,
        IconButton,
    },
    data() {
        return {
            isSubmitting: false,
            brand: {
                name: '',
                deduplication_timeframe: 90,
            },
        }
    },
    computed: {
        ...mapGetters('brands', [ 'brandList', 'getErrorState' ]),
        brandSaveError() {
            return this.getErrorState('brandSave')
        },
    },
    created() {
        this.initializeCustomValidationRules()
    },
    methods: {
        ...mapActions('brands', [ 'addBrand' ]),
        close() {
            this.$emit('close')
        },
        initializeCustomValidationRules() {
            extend('unique-brand', {
                validate: value => {
                    const brandNames = this.brandList.map(brand => brand.name.toLowerCase())
                    return !brandNames.includes(value.toLowerCase())
                },
                message: () => 'A brand with this name already exists',
            })
        },
        submit() {
            this.isSubmitting = true
            this.addBrand({
                ...this.brand,
                deduplication_timeframe: this.brand.deduplication_timeframe === ''
                    ? 90
                    : this.brand.deduplication_timeframe,
            })
                .then(() => {
                    if (this.brandSaveError) {
                        this.handleErrorMessage(this.brandSaveError)
                    } else {
                        this.$_toasterSuccess('Created brand successfully')

                        this.$emit('reload')
                        this.close()
                    }
                })
                .finally(() => {
                    this.isSubmitting = false
                })
        },
        handleErrorMessage(brandSaveError) {
            if (brandSaveError.status === 422) {
                return this.$_toasterDanger(brandSaveError.data.message)
            }
            return this.$_toasterDanger('There was an error creating the brand.')
        },
    },
}
</script>
