import auth from '@api/auth' //eslint-disable-line
import _ from 'lodash'

export default {
    namespaced: true,
    state: {
        user: null,
        logoutTimestamp: null,
    },
    getters: {
        canManage: ({ user }, getters) => userToManage => {
            const superUserRoles = [ 'admin', 'software_engineer' ]

            // logged in user can edit anyone if they are a super user
            if (_.intersection(superUserRoles, getters.userRoleCodes).length) return true

            // logged in user cannot edit their profile
            if (user.uuid === userToManage.uuid) return false

            // logged in user cannot edit userToManage if userToManage has manager-level permissions or above
            const rolesWithEditPermissions = userToManage.roles
                .filter(({ code }) => superUserRoles.includes(code) || code.includes('manager'))

            return !rolesWithEditPermissions.length
        },
        hasScope: ({ user }) => scope => user.scopes.includes(scope),
        hasFeature: ({ user }) => feature => user.features.includes(feature),
        logoutTimestamp: ({ logoutTimestamp }) => logoutTimestamp,
        user: ({ user }) => user,
        userFullName: ({ user }) => (user ? `${user.first_name} ${user.last_name}` : null),
        userManagedTeams: ({ user }) => user.roles
            .filter(role => role.code.toLowerCase().includes('manager'))
            .map(role => role.code.split('_manager')[0]),
        userRoles: ({ user }) => user.roles.sort((role1, role2) => (role1.name < role2.name ? -1 : 1)),
        userRoleCodes: ({ user }) => user.roles.map(role => role.code),
    },
    mutations: {
        setUser(state, user) {
            state.user = user
        },
        setLogoutTimestamp(state, timestamp) {
            state.logoutTimestamp = timestamp
        },
    },
    actions: {
        login({ commit }, {
            email, password, redirect, recaptcha,
        }) {
            return auth.login(email, password, redirect, recaptcha)
                .then(({ data }) => {
                    const user = data.data

                    if (user.uuid) {
                        commit('setUser', user)
                    }

                    return user
                })
        },
        logout({ commit }) {
            return auth.logout()
                .then(() => {
                    commit('setUser', null)
                })
        },
        refreshLogin() {
            return auth.me()
        },
        setLogoutTimestamp({ commit }, timestamp) {
            commit('setLogoutTimestamp', parseInt(timestamp, 10) * 1000)
        },
    },
}
