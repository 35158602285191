<template>
    <VueRecaptcha
        v-if="enabled"
        ref="recaptcha"
        :sitekey="siteKey"
        size="invisible"
        @verify="onCaptchaVerified"
        @expired="resetRecaptcha"></VueRecaptcha>
</template>

<script>
import { VueRecaptcha } from 'vue-recaptcha';
import { recaptchaKey } from '../../keyProvider';

export default {
    components: {
        VueRecaptcha,
    },
    data() {
        return {
            recaptchaObserver: null,
            recaptchaKey,
        }
    },
    computed: {
        enabled() {
            return !!this.recaptchaKey
        },
        siteKey() {
            return this.recaptchaKey
        },
    },
    mounted() {
        this.initializeRecaptchaChallengeObserver()
    },
    methods: {
        execute() {
            // If recaptcha script failed to load (i.e. due to firewall), immediately return
            // with empty string
            try {
                this.$refs.recaptcha.execute()
            } catch (e) {
                this.$emit('verified', '')
            }
        },
        onCaptchaVerified(recaptchaToken) {
            this.resetRecaptcha()
            // Must recreate observer to add click event to challenge background
            // as the background element is destroyed and recreated on captcha reset
            this.initializeRecaptchaChallengeObserver()
            this.$emit('verified', recaptchaToken)
        },
        recaptchaChallengeObserverCallback() {
            const recaptchaChallengeSelector = 'iframe[src^="https://www.google.com/recaptcha"][src*="bframe"]'
            const recaptchaChallengeElement = document?.querySelector(recaptchaChallengeSelector)
            if (recaptchaChallengeElement) {
                // Disconnect mutation observer now that recaptcha divs have been created and selected
                this.recaptchaObserver.disconnect()

                const recaptchaChallengeBackground = recaptchaChallengeElement.parentNode.parentNode.firstChild
                recaptchaChallengeBackground.addEventListener('click', () => {
                    this.$emit('challengeCancelled')
                })
            }
        },
        resetRecaptcha() {
            this.$refs.recaptcha.reset()
        },
        initializeRecaptchaChallengeObserver() {
            this.recaptchaObserver = new MutationObserver(this.recaptchaChallengeObserverCallback)
            this.recaptchaObserver.observe(
                document.documentElement || document.body, { childList: true, subtree: true },
            );
        },
    },
};
</script>
