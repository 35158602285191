import auth from "@store/auth" //eslint-disable-line
import brands from "@store/brands" //eslint-disable-line
import features from '@store/features' //eslint-disable-line
import users from "@store/users" //eslint-disable-line
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export const modules = ({
    auth,
    users,
    brands,
    features,
})

export const store = new Vuex.Store({ modules })
