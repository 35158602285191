var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('VueGoodTable',{ref:"brands",attrs:{"cols":_vm.columns,"rows":_vm.rows,"class-name":"brands-table","search-options":{
            enabled: true,
            placeholder: 'Search brands...',
        },"pagination-options":{
            enabled: true,
        }},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'actions')?_c('span',[_c('router-link',{attrs:{"to":`/brands/${props.row.uuid}`}},[_vm._v(" View"),_c('i',{staticClass:"fas fa-chevron-right ml-2",attrs:{"aria-hidden":"true"}})])],1):(props.column.field === 'deduplication_timeframe'
                    && props.row.deduplication_timeframe === 0)?_c('span',[_vm._v(" All Time ")]):_vm._e()]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }