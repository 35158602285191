<template>
    <BaseCard :is-loading="isLoading">
        <template #title>
            <div>
                <i
                    aria-hidden="true"
                    class="fa fa-users fa-md card__title-icon mr-2" />Users
                <span class="text-muted">[{{ usersList.length }}]</span>
            </div>
        </template>

        <template #body>
            <div
                v-if="!apiRequestFailed"
                class="user-list__search-box">
                <div class="user-list__search-box__col">
                    <input
                        v-model="searchText"
                        type="text"
                        class="form-control"
                        placeholder="Search...">
                </div>
                <div class="user-list__search-box__col">
                    <Checkbox
                        v-model="showTeamOnly"
                        label="Show only my team">
                    </Checkbox>
                </div>
                <div class="user-list__search-box__col">
                    <Checkbox
                        v-model="showInactive"
                        label="Show inactive users">
                    </Checkbox>
                </div>
            </div>

            <div v-if="usersList.length > 0">
                <div class="user-list__info-table-header">
                    <div class="user-list__info-table-col user-list__info-table-col-avatar" />
                    <div
                        class="user-list__info-table-col user-list__info-table-col-name cursor-pointer"
                        @click="setSorting('display_name')">
                        <span>
                            Full Name
                        </span>
                        <span
                            v-if="sortBy === 'display_name'"
                            class="user-list__sort-icon">
                            <i
                                aria-hidden="true"
                                :class="`fas ml-2 ${sortDirectionClass}`" />
                        </span>
                    </div>
                    <div
                        class="user-list__info-table-col user-list__info-table-col-email cursor-pointer"
                        @click="setSorting('email')">
                        Email
                        <span
                            v-if="sortBy === 'email'"
                            class="user-list__sort-icon">
                            <i
                                aria-hidden="true"
                                :class="`fas ml-2 ${sortDirectionClass}`" />
                        </span>
                    </div>
                    <div class="user-list__info-table-col user-list__info-table-col-roles">
                        Roles
                    </div>
                    <div class="user-list__info-table-col user-list__info-table-col-actions" />
                </div>
                <div class="user-list__info-table-body">
                    <div
                        v-for="(user, index) in usersList || []"
                        :key="index"
                        :class="`user-list__info-table-body-row--${user.status}`"
                        class="user-list__info-table-body-row">
                        <div class="user-list__info-table-col user-list__info-table-col-avatar">
                            <div class="user-list__info-table-col-avatar-img">
                                <avatar :image-src="avatarUrl(user)"></avatar>
                            </div>
                        </div>
                        <div class="user-list__info-table-col user-list__info-table-col-name">
                            {{ user.first_name && user.last_name ? `${user.first_name} ${user.last_name}` : '--' }}
                        </div>
                        <div class="user-list__info-table-col user-list__info-table-col-email">
                            {{ user.email }}
                        </div>
                        <div class="user-list__info-table-col user-list__info-table-col-roles">
                            <span
                                v-for="(role, roleIndex) in user.roles"
                                :key="roleIndex">
                                <span v-if="roleIndex != 0">, </span>
                                <span>{{ role.name }}</span>
                            </span>
                        </div>
                        <div class="user-list__info-table-col user-list__info-table-col-actions">
                            <div v-if="user.status === 'inactive'">
                                <button
                                    class="btn btn-success-transparent user-list__activate-user__submit py-2"
                                    @click="activateUser(user.uuid, user.email)">
                                    <i
                                        aria-hidden="true"
                                        class="fa fa-play-circle" /> Activate User
                                </button>
                            </div>
                            <div
                                v-else-if="user.status === 'invited'"
                                v-scope:manage-users>
                                <button
                                    class="btn btn-success-transparent py-2 mr-2"
                                    @click="resendInvite(user.email)">
                                    <i
                                        aria-hidden="true"
                                        class="fas fa-envelope" /> Resend Invite
                                </button>
                                <button
                                    class="btn btn-danger-transparent py-2"
                                    @click="cancelInvite(user.uuid, user.email)">
                                    <i
                                        aria-hidden="true"
                                        class="fas fa-times-circle" /> Cancel Invite
                                </button>
                            </div>
                            <div
                                v-else
                                class="py-2">
                                <router-link :to="{ name: 'Profile', params: { user_uuid: user.uuid } }">
                                    View<i
                                        class="fas fa-chevron-right ml-2"
                                        aria-hidden="true" />
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div
                v-else
                class="mt-2 mb-3">
                <div v-if="apiRequestFailed">
                    <h2 class="d-inline">
                        <i
                            aria-hidden="true"
                            class="text-danger mr-3 fas fa-exclamation-triangle" />
                    </h2>
                    <h5 class="d-inline">
                        We were unable to retrieve users at this time. Please try again or contact support.
                    </h5>
                </div>
                <div
                    v-else
                    class="pt-4">
                    <h5>
                        No users match your criteria. Please try again.
                    </h5>
                </div>
            </div>
        </template>
    </BaseCard>
</template>

<script>
import api from '@api/accounts'
import md5 from 'md5'
import Swal from 'sweetalert2'
import { mapGetters, mapActions } from 'vuex'
import {
    Avatar,
    BaseCard,
    Checkbox,
} from '@technologyadvice/relay-frontend'

const SORT_DIRECTION = {
    ASC: 'ASC',
    DESC: 'DESC',
}

export default {
    name: 'ListUsers',
    components: {
        Avatar,
        BaseCard,
        Checkbox,
    },
    data() {
        return {
            showInactive: false,
            showTeamOnly: true,
            searchText: '',
            sortBy: 'display_name',
            sortDirection: SORT_DIRECTION.DESC,
        }
    },
    computed: {
        ...mapGetters('auth', [
            'userManagedTeams',
        ]),
        ...mapGetters('auth', {
            authUser: 'user',
        }),
        ...mapGetters('users', [
            'users',
            'getLoadingState',
            'getErrorState',
        ]),
        apiRequestFailed() {
            return this.getErrorState('user')
        },
        isLoading() {
            return this.getLoadingState('user')
        },
        sortDirectionClass() {
            return this.sortDirection === SORT_DIRECTION.DESC
                ? 'fa-arrow-up'
                : 'fa-arrow-down'
        },
        usersList() {
            const filteredData = this.applyFilters(this.users)
            return this.applySorting(filteredData)
        },
    },
    mounted() {
        this.loadUserData()
    },
    methods: {
        ...mapActions('users', [
            'fetchUsers',
            'updateUserByUuid',
            'deleteUserByUuid',
        ]),
        loadUserData() {
            this.fetchUsers()
        },
        activateUser(userUuid, email) {
            Swal.fire({
                text: `Confirm that you want to activate: ${email}`,
                title: 'User Activate',
                type: 'success',
                showCancelButton: true,
                confirmButtonText: 'Yes, activate',
                reverseButtons: true,
            })
                .then(result => {
                    if (result.value) {
                        api.activateUser(userUuid)
                            .then(({ data }) => {
                                this.updateUserByUuid({ status: data.data.status, uuid: userUuid })

                                // inform user of success
                                this.$_toasterSuccess('User activated successfully!')
                            })
                            // inform user of error
                            .catch(() => {
                                this.$_toasterDanger('Could not activate user. Please try again or contact support.')
                            })
                    }
                })
        },
        avatarUrl(user) {
            return user.avatar_url ? user.avatar_url : `https://www.gravatar.com/avatar/${md5(user.email)}?s=30&d=mp`
        },
        applyFilters(data) {
            let filteredData = [ ...data ]

            const searchText = this.searchText.toLowerCase().trim()

            // filter on search text
            if (searchText) filteredData = this.filterBySearchText(searchText, filteredData)

            // filter out current user from list
            filteredData = filteredData.filter(user => user.uuid !== this.authUser.uuid)

            // filter on inactive
            if (!this.showInactive) filteredData = filteredData.filter(user => user.status !== 'inactive')

            // filter on user's team
            if (this.showTeamOnly) filteredData = this.filterByTeam(filteredData)

            return filteredData
        },
        applySorting(data) {
            const { sortDirection, sortBy } = this
            const sortValue = sortDirection === SORT_DIRECTION.DESC ? 1 : -1

            return [ ...data ].sort(
                (firstUser, secondUser) => (firstUser[sortBy].toLowerCase() > secondUser[sortBy].toLowerCase()
                    ? sortValue
                    : (-sortValue)),
            )
        },
        resendInvite(email) {
            Swal.fire({
                text: `This will send a new invite link to: ${email}. `
                    + 'Previous invite links will no longer work, they must use the new link.',
                title: 'Resend Invite',
                type: 'info',
                showCancelButton: true,
                confirmButtonText: 'Yes, send it',
                reverseButtons: true,
            })
                .then(result => {
                    if (result.value) {
                        api.sendInvite({ email })
                            // inform user of success
                            .then(() => {
                                this.$_toasterSuccess('Invite resent!')
                            })
                            // inform user of error
                            .catch(() => {
                                this.$_toasterDanger('Could not resend invite. Please try again or contact support.')
                            })
                    }
                })
        },
        cancelInvite(uuid, email) {
            Swal.fire({
                text: `Confirm that you want to cancel invite for: ${email}`,
                title: 'Cancel Invite',
                type: 'error',
                showCancelButton: true,
                confirmButtonText: 'Yes, cancel',
                reverseButtons: true,
            })
                .then(result => {
                    if (result.value) {
                        api.deleteUser(uuid)
                            .then(() => {
                                this.deleteUserByUuid({ uuid })

                                // inform user of success
                                this.$_toasterSuccess('Cancelled invite successfully!')
                            })
                            // inform user of error
                            .catch(() => {
                                this.$_toasterDanger('Could not cancel invite. Please try again or contact support.')
                            })
                    }
                })
        },
        filterBySearchText(searchText, data) {
            return data.filter(user => {
                const nameIncludesSearchText = user.display_name.toLowerCase().includes(searchText)
                const emailIncludesSearchText = user.email.toLowerCase().includes(searchText)
                return nameIncludesSearchText || emailIncludesSearchText
            })
        },
        filterByTeam(data) {
            return data.filter(user => {
                let isTeamUser = false

                user.roles.forEach(role => {
                    const userType = role.code.split('_user')[0]
                    const managerType = role.code.split('_manager')[0]
                    if (
                        this.userManagedTeams.includes(userType)
                        || this.userManagedTeams.includes(managerType)
                    ) isTeamUser = true
                })

                return isTeamUser
            })
        },
        setSorting(key) {
            if (this.sortBy === key) {
                this.sortDirection = this.sortDirection === SORT_DIRECTION.DESC
                    ? SORT_DIRECTION.ASC
                    : SORT_DIRECTION.DESC
            } else {
                this.sortDirection = SORT_DIRECTION.DESC
            }

            this.sortBy = key
        },
    },
}
</script>
