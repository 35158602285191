<template>
    <div
        v-cloak
        class="container-fluid h-100 loginV2__bkg">
        <div class="d-flex justify-content-center align-items-center h-100">
            <div class="reset-password__container">
                <div class="mb-4">
                    <img
                        alt="Traction Logo"
                        :src="logo"
                        class="loginV2__logo reset-password--logo-main">
                </div>

                <LoadingSpinner
                    v-if="loading"></LoadingSpinner>

                <BaseCard
                    v-else
                    class="card-reset-password"
                    :show-header="false">
                    <template #body>
                        <div class="d-flex">
                            <div class="card-reset-password__sidebar" />

                            <div class="card-reset-password__body">
                                <template v-if="invalidLink">
                                    <div>
                                        <h5 class="mb-2">
                                            Oops!
                                        </h5>
                                        <div>
                                            You've followed an invalid or expired link.
                                            If you would like to request a new one, please fill out the form below.
                                        </div>
                                        <FormForgotPassword
                                            :from-link="true">
                                        </FormForgotPassword>
                                    </div>
                                </template>

                                <template v-else>
                                    <div v-if="isSetup">
                                        <h4>Welcome!</h4>
                                        <div class="mb-4">
                                            Before you can continue using Traction, we need to setup your account.
                                        </div>
                                        <h5 class="mb-3">
                                            Update Password
                                        </h5>
                                    </div>
                                    <div v-else>
                                        <h5 class="mb-3">
                                            Reset Password
                                        </h5>
                                    </div>

                                    <FormPassword
                                        :redirect-on-submit="true">
                                    </FormPassword>
                                </template>
                            </div>
                        </div>
                    </template>
                </BaseCard>
            </div>
        </div>
    </div>
</template>

<script>

import api from '@api/accounts'
import logo from '@technologyadvice/relay-frontend/src/assets/brand-logo.svg'
import FormPassword from '@components/common/FormPassword.vue'
import FormForgotPassword from '@/components/common/FormForgotPassword.vue'

import {
    LoadingSpinner,
    BaseCard,
} from '@technologyadvice/relay-frontend'

export default {
    components: {
        BaseCard,
        FormForgotPassword,
        LoadingSpinner,
        FormPassword,
    },
    data() {
        return {
            logo,
            invalidLink: false,
            loading: true,
            isSetup: false,
        }
    },
    created() {
        const { email } = this.$route.query
        const { token } = this.$route.params

        if (!email) {
            this.invalidLink = true
            this.loading = false
        } else {
            // validate token
            api.validatePasswordToken({ email, token })
                .catch(() => {
                    this.invalidLink = true
                })
                .finally(() => {
                    this.loading = false
                    if (this.$route.path.includes('setup')) this.isSetup = true
                })
        }
    },
};
</script>
