<template>
    <div>
        <VueGoodTable
            ref="brands"
            :cols="columns"
            :rows="rows"
            class-name="brands-table"
            :search-options="{
                enabled: true,
                placeholder: 'Search brands...',
            }"
            :pagination-options="{
                enabled: true,
            }">
            <template #table-row="props">
                <span v-if="props.column.field == 'actions'">
                    <router-link :to="`/brands/${props.row.uuid}`">
                        View<i
                            class="fas fa-chevron-right ml-2"
                            aria-hidden="true" />
                    </router-link>
                </span>

                <span
                    v-else-if="props.column.field === 'deduplication_timeframe'
                        && props.row.deduplication_timeframe === 0">
                    All Time
                </span>
            </template>
        </VueGoodTable>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import VueGoodTable from '@technologyadvice/ta-good-table'

export default {
    components: {
        VueGoodTable,
    },
    data() {
        return {
            showInactive: true,
        }
    },
    computed: {
        ...mapGetters('brands', [ 'brandList', 'getLoadingState' ]),
        rows() {
            return this.brandList
        },
        columns() {
            return [
                {
                    label: 'Brand Name',
                    field: 'name',
                    sortable: true,
                },
                {
                    label: 'Deduplication Timeframe (Days)',
                    field: 'deduplication_timeframe',
                    type: 'number',
                    sortable: true,
                },
                {
                    label: 'Created At',
                    field: 'created_at',
                    type: 'date',
                    sortable: true,
                    dateInputFormat: 'y-MM-dd kk:mm:ss',
                    dateOutputFormat: 'E, MMM dd y',
                },
                {
                    field: 'actions',
                    sortable: false,
                },
            ]
        },
    },
    methods: {},
}
</script>
