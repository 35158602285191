const types = [
    {
        name: 'boolean',
        label: 'True / False',
        example: 'Enhanced Product Profile, Product Profile Insights, etc',
    },
    {
        name: 'integer',
        label: 'Number',
        example: 'Leads from Product Listing, Custom Content Pieces, etc',
    },
    {
        name: 'string',
        label: 'String',
        example: 'Scopes (Access Click Campaigns, Access Ad Library, etc)',
    },
    {
        name: 'array',
        label: 'List',
        example: null,
    },
    {
        name: 'solution_categories',
        label: 'Solution Categories',
        example: 'Business Intelligence (BI), Information Technology (IT)',
    },
]

export default {
    install: Vue => {
        Vue.prototype.$readableTypes = {}

        Vue.prototype.$readableTypes.all = () => types

        Vue.prototype.$readableTypes.get = value => types.find(type => type.name === value || type.label === value) || {
            name: value,
            label: `${value[0].toUpperCase()}${value.slice(1).toLowerCase()}`,
        }
    },
}
